import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import { Auth } from 'aws-amplify';
import GenerateInput from '../../utils/GenerateInput';
import useForm from '../../utils/hooks/useForm';
import LoadingSpinner from '../../utils/LoadingSpinner';
import ErrorMessage from '../form/ErrorMessage';
import { post } from '../../services/Api';
import { en } from '../../utils/translations/en';

const validators = {
  firstName: 'alphaOnly',
  lastName: 'alphaOnly',
  email: 'email',
  password: 'password',
  termsAndCond: 'checked'
};

const allowedCountries = [
  'ie',
  'gb',
  'br',
  'fr',
  'de',
  'in',
  'it',
  'lv',
  'lt',
  'pl',
  'ro',
  'es',
  'ua',
  'us',
  'se',
  'at',
  'fi'
];

const RegistrationForm = ({ setConfirmation, setEmail, setPhone }) => {
  const history = useHistory();
  const emailValue = history?.location?.state?.email || '';

  const getPlanFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    for (const [key] of params.entries()) {
      if (key) {
        return key.split('&')[0]; // Handle Google Analytics parameters
      }
    }
    return '';
  };

  const planParam = getPlanFromUrl();
  const isNicheLivingRathmines = planParam === 'nlrathmines';

  const initialState = {
    firstName: '',
    lastName: '',
    email: emailValue,
    password: '',
    confirmPassword: '',
    termsAndCond: false,
    marketingConsent: false
  };

  const { getInputBind, state, isFormValid, error, setError, errorFixed } =
    useForm(initialState, validators);
  const [loading, setLoading] = useState(false);
  const [phoneInput, setPhoneInput] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [rawPhone, setRawPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [isResidentConfirmed, setIsResidentConfirmed] = useState(false);

  useEffect(() => {
    const validPlans = [
      'paygeur',
      'payggbp',
      'sbeur',
      'sbgbp',
      'bundleeur',
      'bundlegbp',
      'elmparkgreen',
      'santryplace',
      'thealliance',
      'walledgarden',
      'intel',
      'stclarespark',
      'taraview',
      'europehotel',
      'nlrathmines',
      'prussiastreet'
    ];

    if (validPlans.includes(planParam)) {
      if (
        !isNicheLivingRathmines ||
        (isNicheLivingRathmines && isResidentConfirmed)
      ) {
        sessionStorage.setItem('plan', planParam);
      }
    }
  }, [planParam, isResidentConfirmed, isNicheLivingRathmines]);

  const residentialPlanNames = [
    'elmparkgreen',
    'santryplace',
    'thealliance',
    'walledgarden',
    'stclarespark',
    'taraview',
    'nlrathmines',
    'prussiastreet'
  ];
  const corporatePlanNames = ['intel', 'europehotel'];

  const residentialPlanName = residentialPlanNames.includes(planParam)
    ? planParam
    : '';
  const corporatePlanName = corporatePlanNames.includes(planParam)
    ? planParam
    : '';

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleResidentConfirmation = isResident => {
    if (isResident) {
      setIsResidentConfirmed(true);
      sessionStorage.setItem('plan', 'nlrathmines');
    } else {
      history.push('/register');
    }
  };

  const handlePhoneChange = (phoneInputVal, country) => {
    setPhoneInput(phoneInputVal);
    setRawPhone(phoneInputVal.slice(country?.dialCode?.length));
    setCountryCode(country?.dialCode);
    let rawPhoneInputVal = phoneInputVal.slice(country?.dialCode?.length);
    if (
      rawPhoneInputVal?.length &&
      (rawPhoneInputVal?.length < 8 || rawPhoneInputVal?.length > 12)
    ) {
      setPhoneError(en?.Error_Full_mobile_number);
    } else {
      setPhoneError('');
    }
  };

  const handleSignUp = async e => {
    e.preventDefault();
    setLoading(true);

    const {
      firstName,
      lastName,
      email,
      password,
      termsAndCond,
      marketingConsent
    } = state;

    if (
      !isFormValid() ||
      loading ||
      !errorFixed ||
      rawPhone?.length < 8 ||
      rawPhone?.length > 12
    ) {
      return;
    }
    try {
      const trimPhoneSpaces = rawPhone.replace(/\s/g, '');
      const formattedPhone =
        trimPhoneSpaces.substring(0, 1) === '0'
          ? trimPhoneSpaces.substring(1)
          : trimPhoneSpaces;
      const finalPhoneFormat = '+' + countryCode + formattedPhone;
      const res = await post(`${process.env.REACT_APP_API}/user`, {
        tel: finalPhoneFormat
      });
      const response = await res?.data?.response;
      const op_id = response?.id;

      const formatName = str =>
        str.length > 50
          ? str.substring(0, 50)
          : str.replace(
              /(\w|[àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšž])+/g,
              txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            );

      const token = await executeRecaptcha('sign_up');

      if (op_id?.length) {
        await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email,
            family_name: formatName(lastName),
            given_name: formatName(firstName),
            phone_number: finalPhoneFormat,
            preferred_username: op_id,
            'custom:terms-and-cond': termsAndCond ? '1' : '0',
            'custom:marketing-cons': marketingConsent ? '1' : '0',
            'custom:residential-plan': residentialPlanName,
            'custom:corporate-plan': corporatePlanName,
            'custom:favourite-location': ''
          },
          validationData: { state: token }
        });

        setLoading(false);
        setEmail(email);
        setPhone(finalPhoneFormat);
        setConfirmation(true);
      } else {
        setError(en?.Error_Creating_Account);
      }
    } catch (error) {
      if (error.code === 'UserLambdaValidationException') {
        setError(en?.Error_Already_have_an_account);
      } else setError(en?.Error_Creating_Account);
      setLoading(false);
    }
    setLoading(false);
  };

  if (isNicheLivingRathmines && !isResidentConfirmed) {
    return (
      <div className="flex w-full justify-center pb-6 pt-2 md:pb-24 md:pt-4">
        <Helmet>
          <title>OOHPod - Niche Living Rathmines</title>
        </Helmet>
        <div className="border-0 bg-white px-4 rounded-2xl border-ooh-line max-w-ooh-pre-login-cart w-full md:border md:px-6 md:py-10">
          <h2 className="form-title mb-6 md:mb-8">
            Hi, are you a resident of Niche Living Rathmines?
          </h2>
          <p>
            <strong>Note: </strong>Please register with the same email address
            you used for the NicheLiving App
          </p>
          <div className="flex space-x-4">
            <button
              onClick={() => handleResidentConfirmation(true)}
              className="px-4 py-2 bg-ooh-green text-white rounded hover:bg-green-700"
            >
              Yes
            </button>
            <button
              onClick={() => handleResidentConfirmation(false)}
              className="px-4 py-2 bg-ooh-red text-white rounded hover:bg-red-700"
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full justify-center pb-6 pt-2 md:pb-24 md:pt-4">
      <Helmet>
        <title>OOHPod - Create your account</title>
      </Helmet>
      <div className="border-0 bg-white px-4 rounded-2xl border-ooh-line max-w-ooh-pre-login-cart w-full md:border md:px-6 md:py-10">
        <h2 className="form-title mb-6 md:mb-8">
          {en?.Lets_create_your_account}
        </h2>
        <form className="space-y-3" onSubmit={handleSignUp}>
          <GenerateInput
            type="text"
            label={en?.First_Name}
            css="capitalize"
            maxLength={50}
            {...getInputBind('firstName')}
          />
          <GenerateInput
            type="text"
            label={en?.Last_Name}
            css="capitalize"
            containerCss="pb-3"
            maxLength={50}
            {...getInputBind('lastName')}
          />
          <GenerateInput
            type="email"
            label={en?.Email}
            autocomplete="email"
            containerCss="pb-5"
            {...getInputBind('email')}
          />
          <div>
            <label htmlFor="phone" className="form-label">
              {en?.Phone}
            </label>
            <PhoneInput
              country={'ie'}
              value={phoneInput}
              onChange={(phoneNo, country) =>
                handlePhoneChange(phoneNo, country)
              }
              onFocus={() => setPhoneFocused(true)}
              onBlur={() => setPhoneFocused(false)}
              onlyCountries={allowedCountries}
              preferredCountries={['ie', 'gb']}
              countryCodeEditable={false}
              inputClass={'form-input'}
              containerClass={'mt-1'}
              enableLongNumbers={true}
              buttonClass={phoneFocused ? 'phone-focused' : ''}
              isValid={(value, country) => {
                let raw = value.slice(country?.dialCode?.length);
                if (raw?.length && (raw?.length < 8 || raw?.length > 12)) {
                  return false;
                } else {
                  return true;
                }
              }}
            />
            <ErrorMessage error={phoneError} />
          </div>
          <GenerateInput
            type="password"
            label={en?.Choose_Password}
            passwordRequirements={true}
            showPassword={true}
            {...getInputBind('password')}
          />
          <div className="pt-3">
            <GenerateInput
              type="checkbox"
              {...getInputBind('marketingConsent')}
            >
              <span>{en?.Marketing_consent}</span>
            </GenerateInput>

            <GenerateInput type="checkbox" {...getInputBind('termsAndCond')}>
              <span>
                {en?.I_have_read}{' '}
                <a
                  className="text-ooh-mauve text-xs"
                  href="https://www.oohpod.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  {en?.Terms_Conditions}
                </a>
                {en?.And_space}{' '}
                <a
                  className="text-ooh-mauve text-xs"
                  href="https://www.oohpod.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {en?.Privacy_Policy}
                </a>
                *
              </span>
            </GenerateInput>
          </div>

          <ErrorMessage error={error} icon />
          <div>
            <button
              disabled={
                !isFormValid() ||
                loading ||
                !errorFixed ||
                rawPhone?.length < 8 ||
                rawPhone?.length > 12
              }
              type="submit"
              className="form-primary-button"
            >
              <LoadingSpinner text={en?.Next_button} loading={loading} />
            </button>
          </div>
          <div className="flex justify-center text-ooh-gray flex-col items-center text-base">
            <span className="">{en?.Already_have_an_account}</span>
            <Link to="/login" className="text-ooh-blue-ui font-bold text-sm">
              {en?.Sign_in}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
