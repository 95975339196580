import React, { useState } from 'react';
import GenerateInput from '../../utils/GenerateInput';
import useForm from '../../utils/hooks/useForm';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../form/ErrorMessage';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { en } from '../../utils/translations/en';

const ForgotPassword = () => {
  const history = useHistory();
  const emailValue = history?.location?.state?.email || '';
  const { getInputBind, state, isFormValid } = useForm(
    { email: emailValue },
    { email: 'email' }
  );
  const [error, setError] = useState(null);

  const sendCode = event => {
    event.preventDefault();
    if (!isFormValid()) return;

    // Send confirmation code to user's email
    Auth.forgotPassword(state.email)
      .then(data => {
        sessionStorage.setItem('email', state.email);
        history.push('/reset', { email: state.email });
      })
      .catch(err => {
        setError(
          'There was an error with your request. Please try again later.'
        );
      });
  };

  return (
    <div className="flex w-full justify-center pb-6 pt-2 md:pb-24 md:pt-4">
      <Helmet>
        <title>OOHPod - Forgot your password</title>
      </Helmet>
      <div className="border-0 bg-white px-4 rounded-2xl border-ooh-line max-w-ooh-pre-login-cart w-full md:border md:px-6 md:py-10">
        <h2 className="form-title">{en?.Forgot_password}</h2>
        <p className="text-ooh-warm-gray pt-3 pb-0 text-center mb-6 md:mb-8">
          {en?.Dont_worry}
        </p>
        <form className="space-y-3" onSubmit={sendCode}>
          <GenerateInput
            type="email"
            label={en?.Enter_email_label}
            placeholder=""
            autocomplete="off"
            {...getInputBind('email')}
          />
          <ErrorMessage error={error} icon />
          <div>
            <button
              disabled={!isFormValid()}
              type="submit"
              className="form-primary-button"
            >
              {en?.Send_Code}
            </button>
          </div>

          <div className="flex justify-center text-ooh-gray flex-col items-center text-base">
            <Link to="/login" className="text-ooh-blue-ui font-bold text-sm">
              {en?.Return_to_sign_in}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
