import { CheckIcon } from '../../utils/Icons';

const ERRORS = [
  'Minimum length 8 characters',
  'Requires Numbers',
  'Requires Special Character',
  'Requires Uppercase letters',
  'Requires Lowercase letters'
];

const PasswordRequirements = ({ value, errors }) => {
  const style = key =>
    errors.includes(key)
      ? 'text-ooh-gray-light'
      : value
      ? 'text-ooh-blue-ui'
      : 'text-ooh-gray-light';

  return (
    <div
      className="absolute top-0 bg-white z-10 w-full p-6 border-b-2 rounded-t-md border-ooh-pale-blue shadow-passwordRequirements"
      style={{ top: '-150px' }}
    >
      {ERRORS.map(error => (
        <div key={error} className={`flex items-center ${style(error)}`}>
          <CheckIcon />
          <span className={`text-sm ml-2 text-ooh-gray`}>{error}</span>
        </div>
      ))}
    </div>
  );
};

export default PasswordRequirements;
