import DashboardCard from '../DashboardCard';
import { useEffect } from 'react';
import { en } from '../../utils/translations/en';
import { LocationMapIcon } from '../../utils/Icons';
import { useHistory } from 'react-router';
import { DashboardRightArrowIcon } from '../../utils/Icons';

let mapLoaded = false;

const Locations = () => {
  useEffect(() => {
    if (!mapLoaded) {
      const script = document.createElement('script');
      script.src = 'https://stockist.co/embed/v1/widget.min.js';
      document.querySelector('head').appendChild(script);
      mapLoaded = true;
    } else {
      window.stockistRebuildWidget();
    }
  }, []);

  const history = useHistory();

  const handleButtonAction = () => {
    sessionStorage.setItem('locationsRedirect', '1');
    history.push('/');
  };

  return (
    <>
      <div className="flex justify-between flex-col md:flex-row md:items-center">
        <h4 className="font-bold text-white flex flex-1 pb-0 items-center mb-2">
          {en?.Locations_Map}
        </h4>
      </div>
      <div>
        <DashboardCard
          buttonText={en?.List_View}
          buttonAction={handleButtonAction}
          title={en?.Map_location}
          icon={<LocationMapIcon css="mr-2" />}
          buttonIcon={<DashboardRightArrowIcon css="ml-2" />}
        >
          <div className="pt-9 pb-7 px-4 md:p-8">
            <div data-stockist-widget-tag="u9611"></div>
          </div>
        </DashboardCard>
      </div>
    </>
  );
};

export default Locations;
