import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import useForm from '../../utils/hooks/useForm';
import LoadingSpinner from '../../utils/LoadingSpinner';
import ResendCode from './ResendCode';
import ErrorMessage from '../form/ErrorMessage';
import VerificationInput from 'react-verification-input';
import mailGif from '../../assets/images/Mail_Gif.gif';
import smsGif from '../../assets/images/SMS_Gif.gif';
import { Helmet } from 'react-helmet';
import { en } from '../../utils/translations/en';

const Confirmation = ({
  confirmationType,
  email,
  verify,
  phone,
  setConfirmation
}) => {
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [codeCompleted, setCodeCompleted] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [value, setValue] = useState('');
  const history = useHistory();
  const { error, setError } = useForm(
    { verificationCode: '' },
    { verificationCode: 'numericOnly' }
  );

  const handleChange = code => {
    setValue(code);
    if (code?.length === 6) {
      setCodeCompleted(true);
    } else {
      setCodeCompleted(false);
    }
  };

  useEffect(() => {
    if (resetError) {
      setError('');
      setValue('');
      setCodeCompleted(false);
      setResetError(false);
    }
  }, [resetError, setError]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (loading) return;
    if (!codeCompleted) return setError('Fill required fields');
    setLoading(true);

    try {
      if (confirmationType === 'email') {
        await Auth.currentAuthenticatedUser().then(user =>
          Auth.verifyUserAttributeSubmit(user, 'email', verificationCode)
        );
        localStorage.setItem('cr', 'true');
        setLoading(false);
        verify();
      } else {
        await Auth.confirmSignUp(email, verificationCode);
        setLoading(false);
        setConfirmation(false);
        history.push('/login', { route: 'SMSconfirmation' });
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <div className="flex w-full justify-center pb-24 pt-4">
      <Helmet>
        <title>OOHPod - Confirm your account</title>
      </Helmet>
      <div className="border-0 bg-white py-10 px-6 rounded-2xl md:border border-ooh-line max-w-ooh-pre-login-cart w-full">
        <h2 className="form-title mb-6 md:mb-8">
          {confirmationType === 'phone' ? en?.Conf_phone : en?.Conf_email}
        </h2>
        <p className="text-ooh-warm-gray p-0 text-center">
          {confirmationType === 'phone' ? phone : email}
        </p>
        <img
          className="mx-auto my-7"
          src={confirmationType === 'phone' ? smsGif : mailGif}
          alt="confirmation"
        />
        <form className="space-y-3 text-center" onSubmit={handleSubmit}>
          <span className="verification-code-label mb-5">
            {en?.Verification_code}
          </span>
          <VerificationInput
            value={value}
            validChars="0-9"
            inputProps={{ autoComplete: 'one-time-code', inputMode: 'numeric' }}
            length={6}
            classNames={{
              container: 'code_container',
              character: 'code_character',
              characterInactive: 'code_character--inactive',
              characterSelected: 'code_character--selected'
            }}
            autoFocus={true}
            placeholder=""
            onComplete={setVerificationCode}
            onChange={handleChange}
          />
          <ErrorMessage error={error} icon />
          <div>
            <button
              disabled={!codeCompleted || loading}
              type="submit"
              className="form-primary-button"
            >
              <LoadingSpinner text={en?.Next_button} loading={loading} />
            </button>
          </div>
        </form>
        <ResendCode
          confirmationType={confirmationType}
          email={email}
          setResetError={setResetError}
        />
      </div>
    </div>
  );
};

export default Confirmation;
