import CheckBox from '../components/form/CheckBox';
import Input from '../components/form/Input';
import InputTel from '../components/form/InputTel';

const GenerateInput = ({
  type = 'text',
  name = '',
  value,
  onChange,
  error,
  label = '',
  placeholder = '',
  autocomplete = 'off',
  css = '',
  containerCss = '',
  children,
  bindPhonePrefix = '+353',
  passwordRequirements = false,
  width,
  disabled,
  showPassword,
  maxLength,
  testData,
  isRequired = true
}) => {
  const err = value?.length ? error : true;
  const border = err !== true ? 'error-border' : '';
  const w = width ? width : 'w-full';
  const style = `${css} ${border} form-input ${
    disabled ? 'bg-ooh-gray-light' : ''
  }`;
  switch (type) {
    case 'text':
    case 'email':
    case 'password':
    default:
      return (
        <Input
          name={name}
          value={value}
          onChange={onChange}
          error={err}
          label={label}
          placeholder={placeholder}
          autocomplete={autocomplete}
          style={style}
          type={type}
          passwordRequirements={passwordRequirements}
          showPassword={showPassword}
          disabled={disabled}
          width={w}
          maxLength={maxLength}
          testData={testData}
          containerCss={containerCss}
          isRequired={isRequired}
        />
      );
    case 'tel':
      return (
        <InputTel
          name={name}
          value={value}
          onChange={onChange}
          error={err}
          label={label}
          placeholder={placeholder}
          autocomplete={autocomplete}
          style={style}
          bindPhonePrefix={bindPhonePrefix}
          border={border}
          testData={testData}
          containerCss={containerCss}
        />
      );
    case 'checkbox':
      return (
        <CheckBox
          name={name}
          value={value}
          onChange={onChange}
          style={css}
          children={children}
          testData={testData}
        />
      );
  }
};

export default GenerateInput;
