import React, { useState } from 'react';
import ChangePassword from './ChangePassword';
import EditAccount from './EditAccount';
import { get, post } from '../../services/Api';
import sessionManangement from '../../utils/userSessionClickEvents';
import { useHistory } from 'react-router';
import DashboardSmallCard from '../layout/DashboardSmallCard';
import {
  CopyIcon,
  DashboardProfileIcon,
  DashboardRightArrowIcon
} from '../../utils/Icons';
import { spacedID } from '../../utils/functions';
import ChargeBeeSmallCard from '../layout/ChargeBeeSmallCard';
import { Auth } from 'aws-amplify';
import { en } from '../../utils/translations/en';
import BuyBundleModal from '../layout/BuyBundleModal';

const Account = ({
  user,
  refresh,
  setRefresh,
  subscriptionName,
  subscriptionId,
  bundleInfo
}) => {
  const history = useHistory();
  const copyIcon = <CopyIcon />;
  const oohIdIcon = <DashboardProfileIcon />;
  const rightArrow = <DashboardRightArrowIcon />;
  const { preferred_username } = user?.attributes || '';
  const [copied, setCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetModal, setResetModal] = useState(false);

  // Function to copy the user's preferred username (OOH ID) to the clipboard
  const copyOOHId = () => {
    const el = document.createElement('input');
    el.value = preferred_username;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied('oohId');
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // Handle the "Manage Subscription" portal opening with session management checks
  const handlePortal = e => {
    e.preventDefault();

    const isExpired = sessionManangement(user);
    if (isExpired) {
      (async () => {
        await Auth.signOut().then(() =>
          history.push('/login', {
            route: 'account-session-expired'
          })
        );
      })();
      return;
    }

    if (!window.Chargebee?.inited) {
      window?.Chargebee.init({
        site: `${process.env.REACT_APP_CHARGEBEE_ENV}`
      });
    }

    const cbInstance = window.Chargebee.getInstance();
    const cbPortal = cbInstance.createChargebeePortal();

    cbInstance.setPortalSession(function () {
      return new Promise(function (resolve, reject) {
        const options = {
          headers: { Authorization: user.signInUserSession?.idToken?.jwtToken }
        };
        get(`${process.env.REACT_APP_API}/user/self-serve`, options)
          .then(res => {
            if (Object.keys(res?.data?.response?.portal_session)?.length) {
              resolve(res?.data?.response?.portal_session);
            } else {
              reject(new Error('No portal session found'));
            }
          })
          .catch(err => {
            console.error('Error fetching portal session:', err);
            history.push('/error'); // Redirect to an error page if session fetch fails
          });
      });
    });

    cbPortal.open({
      close: function () {
        setRefresh(!refresh);
        cbInstance.logout();
      }
    });
  };

  const handleBuyBundles = async quantity => {
    try {
      const options = {
        headers: { Authorization: user.signInUserSession?.idToken?.jwtToken }
      };

      const response = await post(
        `${process.env.REACT_APP_API}/user/checkout`,
        {
          quantity
        },
        options
      );

      if (response.data.status === 'failed') {
        return {
          success: false,
          message:
            'Failed to purchase bundle. Please check your payment card and try again.'
        };
      }

      console.info('Bundles purchased successfully');
      return { success: true };
    } catch (error) {
      console.error('Error purchasing bundles');
      return { success: false, message: 'An unexpected error occurred' };
    }
  };

  const handleCloseModal = () => {
    setRefresh(!refresh); // Refresh the state to reflect changes
    setIsModalOpen(false);
    setResetModal(false); // Reset modal state when closing
  };

  const openModal = () => {
    const isExpired = sessionManangement(user);
    if (isExpired) {
      (async () => {
        await Auth.signOut().then(() =>
          history.push('/login', {
            route: 'account-session-expired'
          })
        );
      })();
      return;
    } else {
      setResetModal(true); // Set this before opening the modal
      setIsModalOpen(true);
    }
  };

  const shouldShowBuyABundleCard =
    subscriptionName.includes('Bundle') && bundleInfo;

  const isBargainBundle = subscriptionName.includes('Bargain Bundle');

  return (
    <>
      <div className="flex justify-between flex-col md:flex-row md:items-center">
        <h4 className="font-bold text-white mb-8 flex flex-1 pb-0 items-center">
          {en?.Manage_Your_Account}
        </h4>
      </div>

      <div className="flex">
        <div className="flex w-full flex-col lg:flex-row">
          <div className="flex w-full flex-col md:flex-row lg:w-2/3">
            <DashboardSmallCard
              icon={oohIdIcon}
              iconCss={'pb-1px'}
              text={spacedID(preferred_username)}
              subtext={`Plan: ${subscriptionName}`}
              subtextCss={
                'block text-ooh-dark-gray font-bold font-body text-xs'
              }
              buttonIcon={copyIcon}
              buttonAction={copyOOHId}
              buttonName="oohId"
              containerCss={'mb-6 md:mb-0 md:mr-6 lg:mr-8 pt-3 pb-11px px-4'}
              textCss={'text-ooh-22'}
              copied={copied}
              copiedText={en?.OOHID_Copied}
            />
            <ChargeBeeSmallCard
              user={user}
              subscriptionId={subscriptionId}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </div>
          <div className="w-full mt-6 lg:mt-0 lg:pl-5.5 lg:w-1/3">
            {shouldShowBuyABundleCard ? (
              <DashboardSmallCard
                text={isBargainBundle ? en.Buy_Bargain_Bundle : en.Buy_Bundle}
                subtext={`You have ${bundleInfo.usesRemaining} of ${bundleInfo.totalUses} uses remaining`}
                subtextCss={
                  'block text-ooh-dark-gray font-bold font-body text-xs'
                }
                textCss={'text-ooh-18'}
                buttonIcon={rightArrow}
                buttonAction={openModal} // Open the modal and reset it
              />
            ) : (
              <DashboardSmallCard
                text={en?.Manage_Subscription}
                buttonIcon={rightArrow}
                buttonAction={handlePortal}
                textCss={'text-ooh-18'}
              />
            )}
          </div>
        </div>
      </div>

      <BuyBundleModal
        user={user}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onBuy={handleBuyBundles}
        reset={resetModal} // Pass reset state to the modal
      />

      <div className="flex flex-col md:flex-row lg:mt-2">
        <EditAccount />
        <ChangePassword />
      </div>
    </>
  );
};

export default Account;
