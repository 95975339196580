import { useCallback, useEffect, useRef, useState } from 'react';
import { en } from '../translations/en';

const useForm = (initialState, validators) => {
  const [state, setState] = useFormState(initialState);
  const [validations, setValidations] = useState(
    initValidations(validators, initialState)
  );
  const [error, SetError] = useState('');
  const [errorFixed, setErrorFixed] = useState(true);

  const setError = useCallback(error => {
    SetError(error);
    setErrorFixed(false);
  }, []);
  useEffect(() => {
    setErrorFixed(true);
  }, [state]);
  useEffect(() => {
    if (state.confirmPassword)
      setValidations(validations => {
        return {
          ...validations,
          confirmPassword: validate(
            validators['confirmPassword'],
            state.confirmPassword,
            { password: state.password }
          )
        };
      });
  }, [validators, setValidations, state.password, state.confirmPassword]);

  const reset = useCallback(() => {
    setState(initialState);
    setValidations(initValidations(validators));
    SetError('');
    setErrorFixed(true);
  }, [
    initialState,
    validators,
    setState,
    setValidations,
    SetError,
    setErrorFixed
  ]);

  const onChange = useCallback(
    e => {
      const { name, value, checked, type } = e.target;
      const v = type === 'checkbox' ? checked : value;
      setState(state => {
        return { ...state, [name]: v };
      });
      if (validators && validators[name] && name !== 'confirmPassword')
        setValidations(validations => {
          return {
            ...validations,
            [name]: validate(validators[name], v)
          };
        });
    },
    [validators, setState, setValidations]
  );

  const isFormValid = useCallback(() => {
    return (
      Object.values(validations).filter(value => value !== true).length === 0
    );
  }, [validations]);

  const getInputBind = useCallback(
    key => {
      const name = key;
      const value = state[key];
      const error = validations[key] != null ? validations[key] : true;
      return { name, value, onChange, error };
    },
    [state, validations, onChange]
  );

  return {
    getInputBind,
    state,
    isFormValid,
    reset,
    error,
    setError,
    errorFixed
  };
};

export default useForm;

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useFormState = initialState => {
  const [state, setState] = useState(initialState);
  const prevProps = usePrevious(initialState);
  useEffect(() => {
    if (JSON.stringify(prevProps) !== JSON.stringify(initialState))
      setState(initialState);
  }, [prevProps, initialState, setState]);
  return [state, setState];
};

const emailRegex =
  /^(([^<>()[\]\\.,;:+\s@"]+(\.[^<>()[\]\\.,;:+\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validate = (type, value, state) => {
  if (!value) return false;
  switch (type) {
    case 'alphaOnly':
      if (
        value?.length &&
        !value?.match(
          /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð,.'’-]+(\s{0,1}[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'’-])*$/
        )
      )
        return en?.Error_Letters_and_spaces_only;
      break;
    case 'phone':
      if (value?.length && !value?.replace(/\s+/g, '').match(/^(\s*\d){9,12}$/))
        return en?.Error_Phone_format;
      break;
    case 'numericOnly':
      if (value?.length && !value?.match(/^\d+$/))
        return en?.Error_Numbers_only;
      break;
    case 'email':
      if (value?.length && !value?.toLowerCase().match(emailRegex))
        return en?.Error_Valid_email_format;
      break;
    case 'password':
      const errors = [];
      if (value.length < 8) errors.push(en?.Error_Min_length_char);
      if (!/\d/.test(value)) errors.push(en?.Error_Requires_Numbers);
      if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value))
        errors.push(en?.Error_Special_Character);
      if (value.toLowerCase() === value)
        errors.push(en?.Error_Uppercase_letters);
      if (value.toUpperCase() === value)
        errors.push(en?.Error_Lowercase_letters);
      if (errors.length) return errors;
      break;
    case 'confirmPassword':
      if (state.password !== value) return en?.Error_Password_not_match;
      break;
    case 'checked':
      return value;
    case 'none':
    default:
  }
  return true;
};

const initValidations = (validators, initialState = {}) => {
  const validations = {};
  if (validators)
    Object.keys(validators).forEach(element => (validations[element] = false));

  // the code below checks if there were any default values set for the input fields and validates them
  if (Object.keys(initialState)?.length) {
    Object.keys(initialState).forEach(element => {
      if (initialState[element]?.length) {
        validations[element] = validate(element, initialState[element]);
      }
    });
  }
  return validations;
};
