import DashboardCard from '../DashboardCard';
import { en } from '../../utils/translations/en';
import {
  ArrowLinkIcon,
  HelpSupportIcon,
  TakeATourIcon
} from '../../utils/Icons';
import { SupportHoursIcon } from '../../utils/Icons';
import DashboardTourV1 from '../../assets/images/DashboardTourV1.png';

const Support = () => {
  return (
    <>
      <div className="flex justify-between flex-col md:flex-row md:items-center">
        <h4 className="font-bold text-white flex flex-1 pb-0 items-center mb-2">
          {en?.Support}
        </h4>
      </div>

      <div>
        <div className="flex flex-col mt-6 lg:flex-row">
          <div className="bg-white border-2 border-ooh-line rounded-2xl p-4 flex flex-1 items-center mb-6 lg:mb-0 lg:mr-6 relative">
            <div className="flex flex-1 flex-col">
              <div className="flex items-center mb-4">
                <HelpSupportIcon css="mr-2" />
                <h4 className="font-heading text-ooh-warm-gray pb-0 text-ooh-18">
                  {en?.How_can_we_help_you}
                </h4>
              </div>
              <div className="text-ooh-warm-gray">
                <span className="text-base">{en?.Check_out}</span>
              </div>
              <div className="pt-6 pb-4 text-center md:text-left">
                <a
                  href="https://help.oohpod.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button
                    className="bg-ooh-mauve rounded-lg border text-lg font-heading font-bold text-white w-full max-w-ooh-input md:max-w-sm md:w-80 self-end py-2 text-ooh-18"
                    test-data="account-subscription-button"
                  >
                    {en?.Help_Support_button}
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border-2 border-ooh-line rounded-2xl p-4 flex-1 flex items-center">
            <div className="flex flex-1 flex-col">
              <div className="flex items-center mb-4">
                <TakeATourIcon css="mr-2" />
                <h4 className="font-heading text-ooh-warm-gray pb-0 text-ooh-18">
                  {en?.Take_Tour}
                </h4>
              </div>
              <div className="text-ooh-warm-gray">
                <span className="text-base">{en?.Oohpod_tour}</span>
              </div>
              <div className="mt-4 mb-2 flex flex-col items-center md:items-start md:w-fit md:mb-4 md:mt-6">
                <img
                  className="max-w-ooh-192 mb-4 md:hidden"
                  src={DashboardTourV1}
                  alt="Dashboard Tour"
                />
                <a
                  href="https://help.oohpod.com/how-do-i-use-the-oohpod-dashboard"
                  rel="noreferrer"
                  target="_blank"
                  className="w-full flex justify-center md:justify-start md:w-auto"
                >
                  <button className="flex border border-ooh-warm-gray max-w-ooh-input rounded-lg items-center py-2 px-12 text-lg font-heading font-bold text-ooh-warm-gray text-ooh-18 mt-4 md:mt-0 justify-center w-full">
                    {en?.Go_to_videos_button}{' '}
                    <ArrowLinkIcon css={'ml-4'} iconColor="#564B51" />
                  </button>
                </a>
              </div>
            </div>
            <div>
              <img
                className="hidden max-w-ooh-192 md:block"
                src={DashboardTourV1}
                alt="Dashboard Tour"
              />
            </div>
          </div>
        </div>

        <DashboardCard
          title={en?.Support_Hours}
          icon={<SupportHoursIcon css="mr-2" />}
        >
          <div className="p-4 pt-0.5 pb-12 md:pb-8 text-ooh-warm-gray">
            <p>
              <span className="font-bold">{en?.Monday_Friday}:</span> 9am - 7pm
            </p>
            <p>
              <span className="font-bold">{en?.Saturday}:</span> 9am - 3pm
            </p>
            <p>
              <span className="font-bold">{en?.Sunday_and_Bank_Holidays}:</span>{' '}
              {en?.Closed_schedule}
            </p>
          </div>
        </DashboardCard>
      </div>
    </>
  );
};

export default Support;
