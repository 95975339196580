import GenerateInput from '../../utils/GenerateInput';
import { useUser } from '../../utils/hooks/Auth';
import useForm from '../../utils/hooks/useForm';
import DashboardCard from '../DashboardCard';
import ErrorMessage from '../form/ErrorMessage';
import sessionManangement from '../../utils/userSessionClickEvents';
import { useHistory } from 'react-router';
import React, { useState } from 'react';
import { ChangePasswordIcon } from '../../utils/Icons';
import { Auth } from 'aws-amplify';
import { en } from '../../utils/translations/en';

const validators = {
  currentPassword: 'required',
  password: 'password',
  confirmPassword: 'confirmPassword'
};

const initialState = {
  currentPassword: '',
  password: '',
  confirmPassword: ''
};

const ChangePassword = () => {
  const user = useUser();
  const { getInputBind, state, isFormValid, reset, error, setError } = useForm(
    initialState,
    validators
  );
  const [changed, setChanged] = useState(false);
  const history = useHistory();
  const submit = e => {
    e.preventDefault();
    setError('');

    //check session
    const isExpired = sessionManangement(user);
    if (isExpired) {
      (async () => {
        await Auth.signOut().then(() =>
          history.push('/login', {
            route: 'mobile-session-expired'
          })
        );
      })();
      return;
    }

    if (!isFormValid()) return;
    Auth.changePassword(user, state.currentPassword, state.password)
      .then(() => {
        reset();
        setChanged(true);
        setTimeout(() => setChanged(false), 3000);
      })
      .catch(err => setError(en?.Error_changing_password));
  };
  return (
    <DashboardCard
      icon={<ChangePasswordIcon css="mr-2" />}
      title={en?.Change_Password_label}
      containerTestData="change-password"
    >
      <form className="" onSubmit={submit}>
        <div className="px-4 pb-6 space-y-6">
          <GenerateInput
            type="password"
            label={en?.Current_Password_label}
            width="w-full max-w-ooh-input md:max-w-sm md:w-80"
            showPassword={true}
            {...getInputBind('currentPassword')}
            testData="old-password"
          />
          <GenerateInput
            type="password"
            label={en?.New_Password}
            width="w-full max-w-ooh-input md:max-w-sm md:w-80"
            showPassword={true}
            passwordRequirements={true}
            {...getInputBind('password')}
            testData="new-password"
          />
          <GenerateInput
            type="password"
            label={en?.Confirm_Password}
            width="w-full max-w-ooh-input md:max-w-sm md:w-80"
            showPassword={true}
            {...getInputBind('confirmPassword')}
            testData="confirm-password"
          />
        </div>
        <div className="px-4 pb-8">
          <ErrorMessage error={error} icon />
          {changed && (
            <p className="text-ooh-green font-bold">
              {en?.Password_successfully}
            </p>
          )}
          <button
            className={
              'bg-ooh-warm-gray rounded-lg text-lg font-heading font-bold text-white w-full max-w-ooh-input md:max-w-sm md:w-80 self-end h-11 ' +
              (isFormValid() ? '' : 'opacity-50')
            }
            disabled={!isFormValid()}
            test-data="change-password-button"
          >
            {en?.Change_password_button}
          </button>
        </div>
      </form>
    </DashboardCard>
  );
};

export default ChangePassword;
