import React from 'react';
import { en } from '../../utils/translations/en';

const Footer = () => {
  return (
    <div className="border-t border-ooh-gray-light">
      <div
        test-data="footer-cart"
        className="footerContainer max-w-screen-ooh-container mx-auto flex flex-col md:flex-row items-center text-center py-6 w-full text-ooh-gray"
      >
        <div className="w-full mx-auto flex flex-col md:flex-row items-center md:w-2/3 justify-evenly md:mb-0">
          <a
            className="mb-1.5 md:mb-0"
            test-data="footer-privacy"
            href="https://www.oohpod.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            {en?.Privacy_Policy_footer_dashboard}
          </a>
          <a
            className="mb-1.5 md:mb-0"
            test-data="footer-terms"
            href="https://www.oohpod.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            {en?.Terms_Conditions}
          </a>
          <a
            className="mb-1.5 md:mb-0"
            test-data="footer-help"
            href="https://help.oohpod.com"
            target="_blank"
            rel="noreferrer"
          >
            {en?.Help_Support_button}
          </a>
        </div>
        <span className="footerCopyright mb-1.5 md:mb-0 md:w-1/3">
          © {new Date().getFullYear()} OOHPod. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default Footer;
