import React, { useState } from 'react';
import LoginForm from './LoginForm';
import Confirmation from './Confirmation';
import { useHistory } from 'react-router-dom';

const Login = ({ setVerified }) => {
  const [confirmation, setConfirmation] = useState(false);
  const [email, setEmail] = useState(false);

  const history = useHistory();
  const prevRoute = history?.location?.state?.route || '';

  const handleConfirmationScreen = () => {
    return confirmation ? (
      <Confirmation
        confirmationType="phone"
        email={email}
        setConfirmation={setConfirmation}
      />
    ) : (
      <LoginForm
        setConfirmation={setConfirmation}
        setEmail={setEmail}
        prevRoute={prevRoute}
        setVerified={setVerified}
      />
    );
  };

  return handleConfirmationScreen();
};
export default Login;
