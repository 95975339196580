import React, { useState, useEffect } from 'react';
import GenerateInput from '../../utils/GenerateInput';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import useForm from '../../utils/hooks/useForm';
import LoadingSpinner from '../../utils/LoadingSpinner';
import ErrorMessage from '../form/ErrorMessage';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { en } from '../../utils/translations/en';

const LoginForm = ({ setConfirmation, setEmail, prevRoute, setVerified }) => {
  const [loading, setLoading] = useState(false);
  const { getInputBind, state, isFormValid, error, setError } = useForm(
    { email: '', password: '' },
    { email: 'email', password: 'required' }
  );
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    setVerified(false);
  }, [setVerified]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (loading) return;
    if (!isFormValid()) return;
    setLoading(true);

    const token = await executeRecaptcha('login');

    const { email, password } = state;
    try {
      localStorage.clear();
      sessionStorage.removeItem('userLocation');
      await Auth.signIn(email, password, { state: token });
      setLoading(false);
      history.push('/');
    } catch (error) {
      setLoading(false);
      setError(error.message);
      if (error.code === 'UserNotConfirmedException') {
        setConfirmation(true);
        setEmail(email);
      }
    }
  };

  return (
    <div className="flex w-full justify-center pb-6 pt-2 md:pb-24 md:pt-4">
      <Helmet>
        <title>OOHPod - Sign into your account</title>
      </Helmet>
      <div className="border-0 bg-white px-4 rounded-2xl border-ooh-line max-w-ooh-pre-login-cart w-full md:border md:px-6 md:py-10">
        <h2
          className={`form-title ${
            prevRoute !== 'SMSconfirmation' ? 'mb-6 md:mb-8' : ''
          }`}
        >
          {prevRoute === 'SMSconfirmation'
            ? en?.Nearly_there
            : en?.Sign_in_to_your_account}
        </h2>
        {prevRoute === 'SMSconfirmation' ? (
          <p className="text-ooh-warm-gray p-0 text-center mb-6 md:mb-8"></p>
        ) : null}
        <form className="space-y-3 mt-10" onSubmit={handleSubmit}>
          <GenerateInput
            type="email"
            label={en?.Email}
            placeholder=""
            autocomplete="email"
            {...getInputBind('email')}
          />

          <GenerateInput
            type="password"
            label={en?.Password}
            showPassword={true}
            {...getInputBind('password')}
          />
          <div className="flex justify-end">
            <Link
              to={{
                pathname: '/forgot',
                state: { email: state.email }
              }}
              className="font-bold text-sm text-ooh-blue-ui mt-2"
            >
              {en?.Forgot_password}
            </Link>
          </div>
          <ErrorMessage error={error} icon />
          <div>
            <button
              disabled={!isFormValid() || loading}
              type="submit"
              className="form-primary-button"
            >
              <LoadingSpinner text={'Sign in'} loading={loading} />
            </button>
          </div>
          {prevRoute !== 'SMSconfirmation' ? (
            <div className="flex justify-center text-ooh-gray flex-col items-center text-base">
              <span className="">{en?.Dont_have_account}</span>
              <Link
                to={{
                  pathname: '/register',
                  state: { email: state?.email }
                }}
                className="text-ooh-blue-ui font-bold text-sm"
              >
                {en?.Sign_up_here}
              </Link>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
