import React, { useState } from 'react';
import { DashboardLeftArrow } from '../../utils/Icons';
import { en } from '../../utils/translations/en';

const COUNTRIES = {
  all: en?.Filter_All,
  roi: en?.Filter_Republic_Ireland,
  ni: en?.Filter_Northern_Ireland
};
const FILTERS = {
  allow_24_7: '24/7',
  dhl_location: en?.DHL_Service_Point,
  allow_oversize: en?.Oversized_Parcels,
  rm_location: 'Royal Mail',
  ups_location: en?.UPS_Access_Point,
  yodel_location: 'Yodel'
};

const initialFilters = {
  all: true,
  roi: false,
  ni: false,
  allow_oversize: false,
  allow_24_7: false,
  yodel_location: false,
  rm_location: false,
  ups_location: false,
  dhl_location: false
};

function LocationsFilters({
  openFilters,
  setOpenFilters,
  setActiveFilters,
  activeFilters,
  locationsNo
}) {
  const [filters, setFilters] = useState(initialFilters);

  const handleCancel = () => {
    setOpenFilters(false);
    setFilters(activeFilters);
  };

  const addFilter = filter => {
    let resetCountries = {};
    if (filter === 'ni' && !filters[filter]) {
      resetCountries = {
        all: false,
        roi: false
      };
    }
    if (filter === 'roi' && !filters[filter]) {
      resetCountries = {
        all: false,
        ni: false
      };
    }
    if (filter === 'all' && !filters[filter]) {
      resetCountries = {
        roi: false,
        ni: false
      };
    }
    setFilters(prevState => ({
      ...prevState,
      ...resetCountries,
      [filter]: !filters[filter]
    }));
  };

  const handleApply = () => {
    setActiveFilters(filters);
    setOpenFilters(false);
  };
  return (
    <div
      className={`filtersContainer z-9999 flex flex-col bg-white max-w-ooh-locations-shelf fixed top-0 right-0 w-full h-full locationsShelfTransition overflow-y-auto ${
        openFilters ? 'translateX0' : 'translateX100'
      }`}
    >
      <div className="topNavigation p-4 lg:p-8">
        <button onClick={handleCancel}>
          <DashboardLeftArrow />
        </button>
      </div>
      <div className="filters text-ooh-warm-gray flex-1 mb-8">
        <div className="mb-16">
          <p className="font-bold border-b border-t-2 boder-ooh-line px-4 py-4 lg:px-8">
            {en?.Select_your_region}
          </p>
          {Object.keys(COUNTRIES).map((country, index) => (
            <p
              onClick={() => addFilter(country)}
              key={index}
              className="px-4 border-b border-ooh-line py-4 flex items-center lg:px-8 cursor-pointer"
            >
              <button
                className={`${
                  filters[country]
                    ? 'bg-ooh-warm-gray'
                    : 'border border-ooh-warm-gray'
                }  w-4 h-4 mr-2 rounded-full`}
              ></button>
              <span className="flex-1">{COUNTRIES?.[country]}</span>{' '}
              <span className="text-ooh-gray-light">
                ({locationsNo?.[country]})
              </span>
            </p>
          ))}
        </div>
        <div>
          <p className="font-bold border-b boder-ooh-line px-4 py-4 lg:px-8">
            {en?.Filter_by}
          </p>
          {Object.keys(FILTERS).map((filter, index) => (
            <p
              onClick={() => addFilter(filter)}
              key={index}
              className="px-4 border-b border-ooh-line py-4 flex items-center lg:px-8 cursor-pointer"
            >
              <button
                className={`${
                  filters[filter]
                    ? 'bg-ooh-warm-gray'
                    : 'border border-ooh-warm-gray'
                }  w-4 h-4 mr-2`}
              ></button>
              <span className="flex-1">{FILTERS?.[filter]}</span>{' '}
              <span className="text-ooh-gray-light">
                ({locationsNo?.[filter]})
              </span>
            </p>
          ))}
        </div>
      </div>
      <div className="filterButtons sticky bottom-0 right-0 bg-white px-4 py-6 border-b border-t border-ooh-line mb-8 items-center flex lg:px-8">
        <button
          onClick={handleApply}
          className="bg-ooh-warm-gray rounded-lg mr-4 text-white py-3 px-8 flex-1"
        >
          {en?.Apply_button}
        </button>
        <button
          onClick={handleCancel}
          className="rounded-lg border border-ooh-line text-ooh-warm-gray py-3 px-8 flex-1"
        >
          {en?.Cancel_button}
        </button>
      </div>
    </div>
  );
}

export default LocationsFilters;
