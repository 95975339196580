import Confirmation from './auth/Confirmation';
import Subscription from './subscription/Subscription';
import UserDashboard from './UserDashboard';
import { Helmet } from 'react-helmet';

const Main = ({
  emailVerified,
  verifyEmail,
  verifySubscription,
  subscribed,
  address,
  subscriptionStatus,
  subscriptionName,
  subscriptionId,
  setRefresh,
  refresh,
  error,
  subscriptionLoading,
  emailLoading,
  user,
  bundleInfo // Added bundleInfo to the props
}) => {
  const renderComponent = () => {
    if (!emailVerified)
      return (
        <Confirmation
          verify={verifyEmail}
          confirmationType="email"
          email={user?.attributes?.email}
        />
      );
    if (!subscribed) return <Subscription verify={verifySubscription} />;
    return (
      <UserDashboard
        address={address}
        subscriptionStatus={subscriptionStatus}
        subscriptionName={subscriptionName}
        subscriptionId={subscriptionId}
        setRefresh={setRefresh}
        refresh={refresh}
        user={user}
        bundleInfo={bundleInfo} // Pass bundleInfo to UserDashboard
      />
    );
  };

  if (emailLoading || subscriptionLoading)
    return (
      <div className="flex flex-col justify-center items-center flex-grow py-10">
        <span className="page-loading"></span>
      </div>
    );

  return (
    <>
      <Helmet>
        <title>OOHPod</title>
      </Helmet>
      {error?.length ? (
        <h1 className="text-center font-lg text-ooh-warm-gray pt-4">{error}</h1>
      ) : null}
      {Object.keys(user)?.length ? renderComponent() : <h1>Loading</h1>}
    </>
  );
};

export default Main;
