import { ErrorIcon } from '../../utils/Icons';
import ErrorMessage from './ErrorMessage';

const InputTel = ({
  name,
  value,
  onChange,
  error,
  label,
  autocomplete,
  style,
  bindPhonePrefix,
  border,
  testData,
  containerCss
}) => {
  const invalid = value ? 'invalid' : '';
  return (
    <div test-data={testData}>
      <label htmlFor={name} className="form-label">
        {label}
        {error ? '*' : ''}
      </label>
      <div className={`relative mt-1 flex ${invalid} ${containerCss}`}>
        <select
          className={`border ${border} rounded-md shadow-sm mr-3 text-ooh-gray`}
          name={bindPhonePrefix.name}
          value={bindPhonePrefix.value}
          onChange={bindPhonePrefix.onChange}
        >
          {(process.env.REACT_APP_ALLOWED_PHONE_PREFIXES || '')
            .split(',')
            .map(prefix => (
              <option key={prefix} value={prefix}>
                {prefix}
              </option>
            ))}
        </select>
        <input
          type="tel"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          autoComplete={autocomplete || 'off'}
          className={style}
        />
        <ErrorIcon error={error} />
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};

export default InputTel;
