import { useEffect, useMemo, useState, useCallback } from 'react';
import { get } from '../../services/Api';
import sessionManagement from '../userSessionClickEvents';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router';

const LIMIT = 40;

const useOrders = user => {
  const [orders, setOrders] = useState([]);
  const [noOrders, setNoOrders] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [resetOrders, setResetOrders] = useState(false);
  const [apiError, setApiError] = useState(false);
  const history = useHistory();

  //For the first time
  const getOrders = useCallback(
    async offset => {
      setLoading(true);
      //Get the first pages of all orders
      const orders = (await getOrdersApi(user, `/orders?offset=${0}`)) || {};
      if (orders?.total > LIMIT) {
        const getBeforeLast = orders.total > LIMIT * 2;
        let last1;
        //Get the page before the last if the user has more than two pages (needed if the last page is not full)
        if (getBeforeLast) {
          last1 =
            (await getOrdersApi(
              user,
              `/orders?offset=${(orders.pages - 2) * LIMIT}`
            )) || {};
        }
        const last2 =
          (await getOrdersApi(
            user,
            `/orders?offset=${(orders.pages - 1) * LIMIT}`
          )) || {};
        //Fill the orders array middle part with empty values between first and last pages
        const to = (orders.pages - (getBeforeLast ? 2 : 1)) * LIMIT;
        for (let i = LIMIT; i < to; i++) orders['orders'].push({});
        if (getBeforeLast) {
          orders['orders'] = [
            ...orders['orders'],
            ...last1.orders,
            ...last2.orders
          ];
        } else {
          orders['orders'] = [...orders['orders'], ...last2.orders];
        }
      }
      return orders;
    },
    [user]
  );

  const getFilteredOrders = useCallback(
    async (from = '', to = '', offset = '') => {
      let query = '';
      setLoading(true);
      if (from?.length && to?.length) {
        query += `/orders?from=${from}&to=${to}`;
        const data = await getOrdersApi(user, query);
        if (data?.apiError) {
          setApiError(true);
        } else {
          setApiError(false);
        }
        // update the orders table
        setOrders(data?.orders);
        setPages(Math.ceil(data.total / 10));
        setNoOrders(data.orders?.length === 0);
      }
      setLoading(false);
    },
    [user]
  );

  const getSingleOrder = useCallback(
    async (trackingNumber = '') => {
      setLoading(true);
      //Get the orders by trackingNumber
      let query = `/orders?trackingNumber=${trackingNumber}`;
      const data = await getOrdersApi(user, query);
      if (data?.apiError) {
        setApiError(true);
      } else {
        setApiError(false);
      }
      // update the orders table
      setOrders(data?.orders);
      setPages(Math.ceil(data.total / 10));
      setNoOrders(data.orders?.length === 0);
      setLoading(false);
    },
    [user]
  );

  useEffect(() => {
    let isMounted = true;
    if (Object.keys(user)?.length) {
      const isExpired = sessionManagement(user);
      if (isExpired) {
        (async () => {
          await Auth.signOut().then(() =>
            history.push('/login', {
              route: 'session-expired'
            })
          );
        })();
        return;
      }
      getOrders().then(data => {
        if (isMounted) {
          if (data?.apiError) {
            setApiError(true);
          } else {
            setApiError(false);
          }
          setOrders(data.orders);
          setPages(Math.ceil(data.total / 10));
          setNoOrders(data.orders?.length === 0);
          setLoading(false);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [user, getOrders, history, resetOrders]);

  const getNext = useCallback(
    async (offset, from = '', to = '') => {
      if (user && Object?.keys?.(orders?.[offset] - 9)?.length === 0) {
        //Get the orders by offset
        let query = `/orders?offset=${offset}`;
        if (from?.length && to?.length) {
          query += `&from=${from}&to=${to}`;
        }

        const next = await getOrdersApi(user, query);
        if (next?.apiError) {
          setApiError(true);
        } else {
          setApiError(false);
        }
        const newOrders = [...orders];
        //Fill the orders array part with the new orders
        if (next?.orders?.length)
          for (let i = 0; i < LIMIT; i++)
            newOrders[i + offset] = next.orders[i];
        setOrders(newOrders);
      }
    },
    [orders, user]
  );

  return useMemo(() => {
    return {
      orders,
      noOrders,
      loading,
      getNext,
      pages,
      getSingleOrder,
      getFilteredOrders,
      setResetOrders,
      resetOrders,
      apiError
    };
  }, [
    orders,
    noOrders,
    loading,
    getNext,
    pages,
    getSingleOrder,
    getFilteredOrders,
    setResetOrders,
    resetOrders,
    apiError
  ]);
};

const getOrdersApi = (user, query) =>
  get(`${process.env.REACT_APP_API}${query}`, {
    headers: { Authorization: user.signInUserSession?.idToken?.jwtToken }
  })
    .then(({ data }) => {
      if (data?.responseMessage === 'There is a problem with your request.') {
        data.response = { ...data?.response, apiError: true };
      }
      return data?.response;
    })
    .catch(err => {
      console.log('request error');
      return { total: 0, pages: 0, orders: [], apiError: true };
    });

export default useOrders;
