import { useState, useEffect, useCallback } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useHistory } from 'react-router';

export const useUser = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    let isMounted = true;
    let updateUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(user => {
          if (isMounted) setUser(user);
        })
        .catch(err => {
          if (isMounted) setUser({});
        });
    };
    // listen for login/signup events
    Hub.listen('auth', updateUser);

    // check manually the first time because we won't get a Hub event
    updateUser();
    // cleanup
    return () => {
      Hub.remove('auth', updateUser);
      isMounted = false;
    };
  }, []);
  return user;
};

export const useLogout = () => {
  const history = useHistory();
  return useCallback(async () => {
    try {
      await Auth.signOut();
      history.push('/login', { route: 'logout' });
    } catch (error) {
      alert(error.message);
    }
  }, [history]);
};
