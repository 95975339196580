import {
  HomeIcon,
  UserIcon,
  SupportIcon,
  UserCircleIcon,
  LocationMarkerIcon
} from '@heroicons/react/outline';
import { useMemo } from 'react';
import { useLogout } from '../../utils/hooks/Auth';
import { OrdersMobileNavIcon } from '../Icons';

const useNavigators = (subscribed, subscriptionStatus) => {
  const logout = useLogout();
  return useMemo(() => {
    return subscribed
      ? subscriptionStatus === 'cancelled'
        ? [{ name: 'Support', href: '/support', icon: SupportIcon }]
        : [
            { name: 'Dashboard', href: '/', icon: HomeIcon },
            { name: 'My Account', href: '/account', icon: UserIcon },
            { name: 'My Orders', href: '/orders', icon: OrdersMobileNavIcon },
            {
              name: 'Locations Map',
              href: '/locations',
              icon: LocationMarkerIcon
            },
            { name: 'Support', href: '/support', icon: SupportIcon }
          ]
      : [{ name: 'Sign out', action: logout, icon: UserCircleIcon }];
  }, [subscriptionStatus, subscribed, logout]);
};

export default useNavigators;
