import React from 'react';
import {
  EditPlanIcon,
  BillingHistoryIcon,
  PaymentIcon
} from '../../utils/Icons';
import { get } from '../../services/Api';
import sessionManangement from '../../utils/userSessionClickEvents';
import { useHistory } from 'react-router';
import { Auth } from 'aws-amplify';
import { en } from '../../utils/translations/en';

function ChargeBeeSmallCard({ user, setRefresh, refresh, subscriptionId }) {
  const history = useHistory();
  const handleClick = e => {
    const cbPageName = e?.target?.name || 'SUBSCRIPTION_DETAILS';

    //check session
    const isExpired = sessionManangement(user);
    if (isExpired) {
      (async () => {
        await Auth.signOut().then(() =>
          history.push('/login', {
            route: 'cb-card-session-expired'
          })
        );
      })();
      return;
    }

    // init CB
    if (!window.Chargebee?.inited) {
      window?.Chargebee.init({
        site: `${process.env.REACT_APP_CHARGEBEE_ENV}`
      });
    }

    // get CB Instance
    const cbInstance = window.Chargebee.getInstance();

    // create CB Portal instance
    var cbPortal = cbInstance.createChargebeePortal();

    cbInstance.setPortalSession(function () {
      // This function should return a promise, that will resolve a portal session object
      return new Promise(function (resolve, reject) {
        const options = {
          headers: { Authorization: user.signInUserSession?.idToken?.jwtToken }
        };
        const response = get(
          `${process.env.REACT_APP_API}/user/self-serve`,
          options
        )
          .then(res => {
            if (Object.keys(res?.data?.response?.portal_session)?.length)
              return res?.data?.response?.portal_session;
          })
          .catch(err => {
            //handle error (navigate to 500)
          });
        resolve(response);
      });
    });

    // open portal
    cbPortal.openSection(
      {
        sectionType: window.Chargebee.getPortalSections()[cbPageName],
        params: { subscriptionId: subscriptionId }
      },
      {
        close: function () {
          // called when chargebee portal is closed
          setRefresh(!refresh);
          cbInstance.logout();
        }
      }
    );
  };

  return (
    <div className="bg-white border-2 border-ooh-line rounded-2xl flex flex-1 items-center py-3 px-4 w-full lg:mr-2.5">
      <button
        onClick={e => handleClick(e)}
        name="SUBSCRIPTION_DETAILS"
        className="flex flex-1 flex-col items-center justify-center"
      >
        <EditPlanIcon css="pointer-events-none" />
        <span className="text-xs text-ooh-warm-gray font-bold mt-2 pointer-events-none">
          {en?.Edit_Plan}
        </span>
      </button>
      <button
        onClick={e => handleClick(e)}
        name="BILLING_HISTORY"
        className="flex flex-1 flex-col items-center justify-center"
      >
        {' '}
        <BillingHistoryIcon css="pointer-events-none" />
        <span className="text-xs text-ooh-warm-gray font-bold mt-2 pointer-events-none">
          {en?.Billing_History}
        </span>
      </button>
      <button
        onClick={e => handleClick(e)}
        name="PAYMENT_SOURCES"
        className="flex flex-1 flex-col items-center justify-center"
      >
        {' '}
        <PaymentIcon css="pointer-events-none" />
        <span className="text-xs text-ooh-warm-gray font-bold mt-2 pointer-events-none">
          {en?.Payment}
        </span>
      </button>
    </div>
  );
}

export default ChargeBeeSmallCard;
