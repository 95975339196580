import React from 'react';
import { Auth } from 'aws-amplify';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setLoggedIn] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      let user = null;

      try {
        user = await Auth.currentAuthenticatedUser();
        if (user) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      } catch (e) {
        setLoggedIn(false);
      }
    })();
  }, []);

  const handleRoute = () => (
    <Route
      render={props =>
        isAuthenticated ? (
          <>{children}</>
        ) : isAuthenticated === false ? (
          <Redirect to="/login" />
        ) : (
          <div className="flex flex-col justify-center items-center flex-grow">
            <span className="page-loading"></span>
          </div>
        )
      }
    />
  );
  return handleRoute();
};

export default ProtectedRoute;
