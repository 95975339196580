const CheckBox = ({ name, value, onChange, style, children, testData }) => (
  <div className="flex" test-data={testData}>
    <div className="mr-2 leading-4">
      <input
        type="checkbox"
        id={name}
        name={name}
        value={value}
        checked={value}
        onChange={onChange}
        className={`${style}`}
      />
    </div>
    <label htmlFor={name} className="form-label-checkbox">
      {children}
    </label>
  </div>
);

export default CheckBox;
