export const COGNITO = {
  REGION: process.env.REACT_APP_CR,
  USER_POOL_ID: process.env.REACT_APP_CUP,
  APP_CLIENT_ID: process.env.REACT_APP_CAID,
  IDENTITY_POOL_ID: process.env.REACT_APP_IPID
};

export const PINPOINT = {
  PROJECT_ID: process.env.REACT_APP_PPID
};
