import React from 'react';
import { useHistory, useLocation } from 'react-router';
import useNavigators from '../../../utils/hooks/useNavigators';
import { classNames } from '../utils';
import sessionManagement from '../../../utils/userSessionClickEvents';
import { useUser } from '../../../utils/hooks/Auth';
import { Auth } from 'aws-amplify';

const DesktopNavigation = ({ subscriptionStatus }) => {
  const location = useLocation();
  const history = useHistory();
  const navigators = useNavigators(true, subscriptionStatus);
  const user = useUser();

  return (
    <div className="hidden lg:flex lg:flex-grow">
      <div
        test-data="topbar-navigation-cart"
        className="flex flex-col flex-grow"
      >
        <nav className="flex px-10">
          {navigators.map((item, index) => {
            const handleClick = () => {
              //check session
              const isExpired = sessionManagement(user);
              if (isExpired) {
                (async () => {
                  await Auth.signOut().then(() =>
                    history.push('/login', {
                      route: 'desktop-session-expired'
                    })
                  );
                })();
                return;
              }
              return item.action ? item.action() : history.push(item.href);
            };
            return (
              <p
                test-data={`navigation-item-${index}`}
                key={item.name}
                onClick={() => handleClick()}
                className={classNames(
                  location.pathname === item.href
                    ? 'bg-ooh-silver'
                    : 'hover:bg-gray-50 hover:text-ooh-warm-gray',
                  'flex items-center mr-4 rounded-lg cursor-pointer text-ooh-warm-gray text-center py-1 px-3 xl:px-4 xl:mr-10'
                )}
              >
                {item.name}
              </p>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default DesktopNavigation;
