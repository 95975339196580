import React, { useState, useMemo } from 'react';
import GenerateInput from '../../utils/GenerateInput';
import useForm from '../../utils/hooks/useForm';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../form/ErrorMessage';
import VerificationInput from 'react-verification-input';
import ringBell from '../../assets/images/ringBell.gif';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { en } from '../../utils/translations/en';

const ResetPassword = () => {
  const history = useHistory();
  const emailValue = history?.location?.state?.email || '';
  const validators = useMemo(
    () => ({
      ...(!emailValue.length && { email: 'email' }),
      password: 'password',
      confirmPassword: 'confirmPassword'
    }),
    [emailValue.length]
  );
  const initialState = {
    email: emailValue,
    password: '',
    confirmPassword: ''
  };
  const { getInputBind, state, isFormValid } = useForm(
    initialState,
    validators
  );
  const [error, setError] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [codeCompleted, setCodeCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = code => {
    if (code?.length === 6) {
      setCodeCompleted(true);
    } else {
      setCodeCompleted(false);
    }
  };
  const resetPassword = event => {
    event.preventDefault();
    if (!isFormValid() || !codeCompleted) return;
    if (loading) return;
    setLoading(true);
    let emailFrom = emailValue?.length ? emailValue : state.email;

    Auth.forgotPasswordSubmit(emailFrom, verificationCode, state.password)
      .then(data => {
        setLoading(false);
        history.push('/login');
      })
      .catch(err => {
        setLoading(false);
        setError(en?.Error_request);
      });
  };

  return (
    <div className="flex w-full justify-center pb-6 pt-2 md:pb-24 md:pt-4">
      <div className="border-0 bg-white px-4 rounded-2xl border-ooh-line max-w-ooh-pre-login-cart w-full md:border md:px-6 md:py-10">
        <h2 className="form-title">{en?.Please_retrieve_your_code}</h2>
        <p className="form-subtitle">{en?.Check_email_or_phone}</p>
        <form className="space-y-3" onSubmit={resetPassword}>
          <img className="mx-auto my-6" src={ringBell} alt="confirmation" />
          {!emailValue?.length ? (
            <GenerateInput
              type="email"
              label={en?.Enter_email_label}
              placeholder=""
              autocomplete="off"
              {...getInputBind('email')}
            />
          ) : null}
          <span className="verification-code-label pb-2">
            {en?.Verification_code}
          </span>
          <VerificationInput
            validChars="0-9"
            inputProps={{ autoComplete: 'one-time-code', inputMode: 'numeric' }}
            length={6}
            classNames={{
              container: 'code_container',
              character: 'code_character',
              characterInactive: 'code_character--inactive',
              characterSelected: 'code_character--selected'
            }}
            autoFocus={true}
            placeholder=""
            onComplete={setVerificationCode}
            onChange={handleChange}
          />

          <p className="form-subtitle pt-4 pb-2 md:pt-8 md:pb-4">
            {en?.Enter_new_password}
          </p>
          <GenerateInput
            type="password"
            label={en?.New_Password}
            passwordRequirements={true}
            showPassword={true}
            {...getInputBind('password')}
            containerCss="pt-2"
          />
          <GenerateInput
            type="password"
            label={en?.Confirm_New_Password}
            {...getInputBind('confirmPassword')}
          />
          <ErrorMessage error={error} icon />
          <div>
            <button
              disabled={!isFormValid() || !codeCompleted || loading}
              type="submit"
              className="form-primary-button"
            >
              <LoadingSpinner
                text={en?.Create_new_password}
                loading={loading}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
