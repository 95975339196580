import React from 'react';

function DashboardSmallCard({
  icon,
  iconCss,
  text,
  favAddress,
  subtext,
  subtextCss,
  buttonIcon,
  buttonAction,
  buttonName = '',
  containerCss,
  textCss,
  copied,
  copiedText
}) {
  return (
    <div
      className={`bg-white border-2 min-h-ooh-small-card border-ooh-line rounded-2xl flex justify-center flex-1 flex-col py-3 px-4 ${
        containerCss ? containerCss : ''
      }`}
    >
      <button onClick={buttonAction} className="flex items-center w-full">
        {icon ? (
          <div className={iconCss?.length ? iconCss : ''}>{icon}</div>
        ) : null}
        <div
          className={`flex-1 ml-2 font-extrabold text-ooh-warm-gray font-heading text-left pr-2 ${
            textCss ? textCss : ''
          }`}
        >
          {buttonName === copied ? (
            <span className="block text-center border border-ooh-line rounded-lg py-1 px-4 text-base">
              {copiedText}
            </span>
          ) : (
            <>
              <span>{text}</span>
              {subtext?.length ? (
                <span className={subtextCss}>{subtext}</span>
              ) : null}
            </>
          )}
        </div>
        <div>{buttonIcon}</div>
      </button>
      {favAddress?.length ? (
        <div className="block md:hidden text-ooh-dark-gray font-bold font-body text-xs pl-12 pr-3 pb-4">
          {favAddress.split(',').map((a, index) => (
            <span key={index} className="pb-0 block ">
              {a}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default DashboardSmallCard;
