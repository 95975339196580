import React, { useState } from 'react';
import useOrders from '../../utils/hooks/useOrders';
import Table from '../Table';
import Datepicker from 'react-tailwindcss-datepicker';
import { ClearSearchIcon, SearchIcon } from '../../utils/Icons';
import { en } from '../../utils/translations/en';
import LoadingSpinner from '../../utils/LoadingSpinner';

const ORDERS_HEADERS = [
  en?.Created_orders,
  en?.Tracking_Number_orders,
  en?.Location_orders,
  en?.Status_orders,
  en?.Last_Updated_orders
];

function Orders({ user }) {
  const [filterDate, setFilterDate] = useState({});
  const [trackingNumber, setTrackingNumber] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [trackingNumberError, setTrackingNumberError] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const handleFilterDateChange = newValue => {
    setFilterDate(newValue);
  };
  const {
    orders,
    noOrders,
    loading,
    getNext,
    pages,
    getSingleOrder,
    getFilteredOrders,
    setResetOrders,
    resetOrders,
    apiError
  } = useOrders(user);

  const handleSearch = e => {
    if (loading) return;
    if (e?.type === 'click' || (e?.type === 'keydown' && e?.keyCode === 13)) {
      if (trackingNumber?.length) {
        if (trackingNumber.replace(/ /g, '').match(/^[a-zA-Z0-9_]*$/)) {
          setTrackingNumberError(false);
          setSearchPerformed(true);
          setFilterDate({ startDate: null, endDate: null });
          getSingleOrder(trackingNumber.replace(/ /g, ''));
          setCurrentPage(1);
        } else {
          setTrackingNumberError(true);
        }
      } else {
        setSearchPerformed(false);
        setTrackingNumberError(false);
        setCurrentPage(1);
        setResetOrders(!resetOrders);
      }
    }
  };

  const handleFilterSearch = () => {
    if (filterDate?.startDate?.length && filterDate?.endDate?.length) {
      setTrackingNumber('');
      getFilteredOrders(filterDate?.startDate, filterDate?.endDate);
      setCurrentPage(1);
      setSearchPerformed(true);
    } else {
      setSearchPerformed(false);
      setResetOrders(!resetOrders);
    }
  };

  const handleReset = () => {
    if (
      trackingNumber?.length ||
      (filterDate?.startDate && filterDate?.endDate)
    ) {
      setFilterDate({ startDate: null, endDate: null });
      setTrackingNumber('');
      setCurrentPage(1);
      setSearchPerformed(false);
      setResetOrders(!resetOrders);
    }
    setTrackingNumberError(false);
  };

  return (
    <div>
      <div className="flex justify-between flex-col md:flex-row md:items-center">
        <h4 className="font-bold text-white mb-8 flex flex-1 pb-0 items-center">
          {en?.My_Orders}
        </h4>
      </div>
      <div className="bg-white border-2 border-ooh-line rounded-xl px-4 pt-4 pb-8 mb-6">
        <span className="text-ooh-warm-gray text-xs font-bold mb-1.5 block">
          {en?.Search_by_tracking_number}
        </span>
        <div className="flex w-full flex-col md:flex-row">
          <div className="w-full mr-0 mb-4 md:w-8/12 md:mr-6 md:mb-0 relative">
            <SearchIcon css="absolute top-4 left-2" />
            <input
              value={trackingNumber}
              onKeyDown={e => handleSearch(e)}
              onChange={e => setTrackingNumber(e?.target?.value)}
              type="text"
              className="w-full bg-ooh-light-silver rounded-lg border border-ooh-line px-9 py-3 text-ooh-warm-gray focus:outline-none focus:border-ooh-pale-blue"
              placeholder={en?.Enter_tracking_number}
            />
            {trackingNumber?.length ? (
              <button
                type="button"
                onClick={handleReset}
                className="absolute h-ooh-input right-0 px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
              >
                <ClearSearchIcon />
              </button>
            ) : null}
            {trackingNumberError ? (
              <div className="error">{en?.Error_Tracking_number_format}</div>
            ) : null}
          </div>
          <div className="w-full flex flex-1 md:w-4/12 font-heading font-bold text-lg h-fit">
            <button
              disabled={loading}
              onClick={e => handleSearch(e)}
              className="bg-ooh-warm-gray flex flex-1 py-2 leading-8 text-white justify-center rounded-lg disabled:opacity-50"
            >
              <LoadingSpinner loading={loading} text={en?.Search_orders} />
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white border-2 border-ooh-line rounded-xl px-4 pt-4 pb-8 mb-6">
        <span className="text-ooh-warm-gray text-xs font-bold mb-1.5 block">
          {en?.Filter_by_date}
        </span>
        <div className="flex flex-col items-center md:flex-row">
          <div className="w-full mr-0 mb-4 md:w-8/12 md:mr-6 md:mb-0 datePicker">
            <Datepicker
              separator={' > '}
              showShortcuts={true}
              displayFormat={'DD/MM/YYYY'}
              value={filterDate}
              onChange={handleFilterDateChange}
              inputClassName="text-ooh-warm-gray bg-ooh-light-silver w-full px-9 py-3 border border-ooh-line rounded-lg focus:outline-none focus:border-ooh-pale-blue"
            />
          </div>
          <div className="w-full flex flex-1 md:w-4/12 font-heading font-bold text-lg">
            <button
              onClick={handleReset}
              className="flex flex-1 text-ooh-warm-gray border border-ooh-warm-gray rounded-lg py-2.5 justify-center mr-6"
            >
              {en?.Reset_orders}
            </button>
            <button
              disabled={loading}
              onClick={handleFilterSearch}
              className="flex flex-1 bg-ooh-warm-gray py-2 leading-8 text-white justify-center rounded-lg disabled:opacity-50"
            >
              <LoadingSpinner loading={loading} text={en?.Apply_orders} />
            </button>
          </div>
        </div>
      </div>
      <Table
        name={en?.All_Orders}
        headers={ORDERS_HEADERS}
        data={orders}
        noData={noOrders}
        performedSearch={searchPerformed}
        loading={loading}
        getNext={getNext}
        pages={pages}
        dashboardTable={false}
        from={filterDate?.startDate || ''}
        to={filterDate?.endDate || ''}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        apiError={apiError}
      />
    </div>
  );
}

export default Orders;
