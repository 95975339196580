import { useState } from 'react';
import { ErrorIcon, EyeIcon } from '../../utils/Icons';
import ErrorMessage from './ErrorMessage';
import PasswordRequirements from './PasswordRequirements';
import { en } from '../../utils/translations/en';

const Input = ({
  name,
  value,
  onChange,
  error,
  label,
  placeholder,
  autocomplete,
  style,
  type,
  passwordRequirements,
  showPassword,
  disabled,
  width,
  maxLength,
  testData,
  containerCss,
  isRequired
}) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [showRequirements, setShowRequirements] = useState(false);
  const invalid = value ? 'invalid' : '';

  return (
    <div className={`relative ${containerCss}`} test-data={testData}>
      <label htmlFor={name} className="form-label">
        {label}
        {isRequired ? '*' : ''}
      </label>
      <div className={`relative mt-1 ${invalid} ${width}`}>
        <input
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={autocomplete || 'off'}
          className={style}
          type={isPasswordShown ? 'text' : type}
          onFocus={() => setShowRequirements(true)}
          onBlur={() => setShowRequirements(false)}
          disabled={disabled}
          maxLength={maxLength}
        />
        {showPassword ? (
          <EyeIcon onClick={() => setIsPasswordShown(!isPasswordShown)} />
        ) : (
          <ErrorIcon error={error} />
        )}
      </div>
      {passwordRequirements ? (
        showRequirements ? (
          <PasswordRequirements
            value={value}
            errors={typeof error === 'boolean' ? [] : error}
          />
        ) : error.length ? (
          <ErrorMessage error={en?.Error_Check_your_password} />
        ) : null
      ) : (
        <ErrorMessage error={error} />
      )}
    </div>
  );
};

export default Input;
