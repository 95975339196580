import React, { useEffect, useState } from 'react';
import Location from './Location';
import {
  CloseIcon,
  FilterByIcon,
  OrdersTableRightArrow
} from '../../utils/Icons';
import LocationsFilters from './LocationsFilters';
import DetailedLocation from './DetailedLocation';
import { en } from '../../utils/translations/en';

const filterLocations = (locationsToFilter, activeFilters) => {
  return locationsToFilter.filter(location => {
    for (let key in activeFilters) {
      if (key === 'roi' && activeFilters?.[key]) {
        if (location?.country !== 'ROI') {
          return false;
        }
      }
      if (key === 'ni' && activeFilters?.[key]) {
        if (location?.country !== 'NI' && activeFilters?.[key]) {
          return false;
        }
      }
      if (key === 'allow_oversize' && activeFilters?.[key]) {
        if (!location?.allow_oversize) {
          return false;
        }
      }
      if (key === 'yodel_location' && activeFilters?.[key]) {
        if (!location?.yodel_location) {
          return false;
        }
      }
      if (key === 'allow_24_7' && activeFilters?.[key]) {
        if (!location?.allow_24_7) {
          return false;
        }
      }
      if (key === 'ups_location' && activeFilters?.[key]) {
        if (!location?.upsAPId) {
          return false;
        }
      }
      if (key === 'dhl_location' && activeFilters?.[key]) {
        if (!location?.dhlId) {
          return false;
        }
      }
      if (key === 'rm_location' && activeFilters?.[key]) {
        if (!location?.rm_location) {
          return false;
        }
      }
    }
    return true;
  });
};

const locationsNo = (locations = []) => {
  let locationsNumbers = {
    all: locations?.length,
    roi: 0,
    ni: 0,
    allow_oversize: 0,
    allow_24_7: 0,
    yodel_location: 0,
    rm_location: 0,
    ups_location: 0,
    dhl_location: 0
  };
  locations.filter(location => {
    if (location?.country === 'ROI') {
      locationsNumbers['roi'] = locationsNumbers['roi'] + 1;
    }
    if (location?.country === 'NI') {
      locationsNumbers.ni = locationsNumbers['ni'] + 1;
    }
    if (location?.allow_oversize) {
      locationsNumbers.allow_oversize = locationsNumbers['allow_oversize'] + 1;
    }
    if (location?.allow_24_7) {
      locationsNumbers.allow_24_7 = locationsNumbers['allow_24_7'] + 1;
    }
    if (location?.yodel_location) {
      locationsNumbers.yodel_location = locationsNumbers['yodel_location'] + 1;
    }
    if (location?.rm_location) {
      locationsNumbers.rm_location = locationsNumbers['rm_location'] + 1;
    }
    if (location?.upsAPId?.length) {
      locationsNumbers.ups_location = locationsNumbers['ups_location'] + 1;
    }
    if (location?.dhlId?.length) {
      locationsNumbers.dhl_location = locationsNumbers['dhl_location'] + 1;
    }
    return false;
  });
  return locationsNumbers;
};

const initialFilters = {
  all: true,
  roi: false,
  ni: false,
  allow_oversize: false,
  allow_24_7: false,
  yodel_location: false,
  rm_location: false,
  ups_location: false,
  dhl_location: false
};

function LocationsList({
  user,
  given_name,
  family_name,
  oohid,
  locations,
  openList,
  setOpenList,
  hideFavourite,
  favouriteLocation
}) {
  const [openFilters, setOpenFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState(initialFilters);
  const [activeFiltersNo, setActiveFiltersNo] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [openDetails, setOpenDetails] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [finalLocations, setFinalLocations] = useState(filteredLocations);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    let filtersNo = 0;
    for (let key in activeFilters) {
      if (activeFilters.hasOwnProperty(key) && activeFilters[key]) {
        filtersNo++;
      }
    }
    setActiveFiltersNo(filtersNo);
    setFilteredLocations(filterLocations(locations, activeFilters));
    setFinalLocations(filterLocations(locations, activeFilters));
    setSearchValue('');
  }, [activeFilters, locations]);

  useEffect(() => {
    if (openList) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [openList]);

  const handleOnChange = e => {
    if (e.target.value?.length) {
      setFinalLocations(
        filteredLocations.filter(
          location =>
            location?.full_name
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            location?.address
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
      );
    } else {
      setFinalLocations(filteredLocations);
    }
    setSearchValue(e?.target?.value);
  };

  return (
    <div>
      <div
        className={`locationsContainer z-9999 bg-white max-w-ooh-locations-shelf fixed top-0 right-0 w-full h-full locationsShelfTransition overflow-y-auto ${
          openList ? 'translateX0' : 'translateX100'
        }`}
      >
        <div className="locationsTopContainer text-right p-8">
          <button onClick={() => setOpenList(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="filtersContainer flex justify-center bg-ooh-blue-ui py-2 relative">
          <button
            onClick={() => setOpenFilters(true)}
            className="flex items-center font-bold text-white px-5 py-1 border rounded-4xl "
          >
            <FilterByIcon css="mr-2" /> {en?.Filter}{' '}
            <OrdersTableRightArrow css={'ml-2'} iconColor="#FFFFFF" />
          </button>

          <span className="flex items-center justify-center w-9 h-9 top-0 right-0 rounded-full border border-white p-3 text-white absolute font-bold my-2 mr-8 ">
            {`${activeFiltersNo > 0 ? `${activeFiltersNo}` : ''}`}
          </span>
        </div>
        <div className="border-b border-ooh-line">
          <div className="py-4 px-4 lg:px-8">
            <input
              value={searchValue}
              type="text"
              className="w-full px-4 py-2  border-2 border-ooh-line bg-ooh-light-silver rounded-lg text-ooh-warm-gray outline-none"
              onChange={e => handleOnChange(e)}
              placeholder={en?.Search_by_locker_address}
            ></input>
          </div>
        </div>
        <div>
          {finalLocations?.length ? (
            finalLocations.map(location => (
              <Location
                key={location?.id}
                location={location}
                setOpenDetails={setOpenDetails}
                setSelectedLocation={setSelectedLocation}
                given_name={given_name}
                family_name={family_name}
                oohid={oohid}
              />
            ))
          ) : (
            <p className="text-ooh-rust p-8 font-bold">
              {en?.Error_no_locations}
            </p>
          )}
        </div>
      </div>
      <div
        className={`overlay fixed top-0 left-0 w-full h-full opacityTransition ${
          openList ? 'opacity-85 z-10' : 'opacity-0 -z-10'
        } bg-black `}
      ></div>
      <LocationsFilters
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        locationsNo={locationsNo(locations)}
      />
      <DetailedLocation
        location={selectedLocation}
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        given_name={given_name}
        family_name={family_name}
        oohid={oohid}
        hideFavourite={hideFavourite}
        favouriteLocation={favouriteLocation}
        user={user}
      />
    </div>
  );
}

export default LocationsList;
