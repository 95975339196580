import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import ErrorMessage from '../form/ErrorMessage';
import { en } from '../../utils/translations/en';

const ResendCode = ({ confirmationType, email, setResetError }) => {
  const [timer, setTimer] = useState(60);
  const [error, setError] = useState('');

  useEffect(() => {
    setInterval(() => setTimer(timer => (timer > 0 ? timer - 1 : 0)), 1000);
    return () => {
      clearInterval();
      setTimer(0);
    };
  }, [setTimer]);

  const resendConfirmationCode = async () => {
    try {
      if (confirmationType === 'email') {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.verifyUserAttribute(user, 'email');
      } else {
        await Auth.resendSignUp(email);
      }
      setResetError(true);
    } catch (err) {
      setError(err.message);
    }
    setTimer(60);
  };

  return (
    <>
      <ErrorMessage error={error} icon />
      <button
        disabled={timer}
        className={`text-base font-bold text-center cursor-pointer w-full ${
          timer ? 'text-ooh-gray-light' : 'text-ooh-warm-gray'
        }`}
        onClick={timer ? null : resendConfirmationCode}
      >
        {en?.Resend_Code}
        <span className="text-ooh-pale-blue">{timer ? ` ${timer}s` : ''}</span>
      </button>
    </>
  );
};

export default ResendCode;
