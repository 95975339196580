const sessionManagement = user => {
  const loggedInTime =
    user?.signInUserSession?.accessToken?.payload['auth_time'];
  let sessionExpired = false;
  if (loggedInTime) {
    if (new Date().getTime() > loggedInTime * 1000 + 1 * 60 * 60 * 1000) {
      sessionExpired = true;
    }
  }
  return sessionExpired;
};

export default sessionManagement;
