import GenerateInput from '../../utils/GenerateInput';
import { useUser } from '../../utils/hooks/Auth';
import useForm from '../../utils/hooks/useForm';
import useCommPreferences from '../../utils/hooks/useCommPreferences';
import DashboardCard from '../DashboardCard';
import ErrorMessage from '../form/ErrorMessage';
import sessionManangement from '../../utils/userSessionClickEvents';
import { useHistory } from 'react-router';
import { patch } from './../../services/Api';
import { DashboardProfileIcon } from '../../utils/Icons';
import { Auth } from 'aws-amplify';
import { en } from '../../utils/translations/en';

const EditAccount = () => {
  const user = useUser();
  const history = useHistory();
  const attributes = user?.attributes || {
    given_name: '',
    family_name: '',
    email: '',
    phone_number: ''
  };
  const { given_name, family_name, email, phone_number } = attributes;
  const { marketingConsent } = useCommPreferences(user);
  const { getInputBind, state, error, setError } = useForm(
    {
      given_name,
      family_name,
      email,
      phone_number,
      marketingConsent
    },
    null
  );
  const submit = e => {
    e.preventDefault();
    const isExpired = sessionManangement(user);
    if (isExpired) {
      (async () => {
        await Auth.signOut().then(() =>
          history.push('/login', {
            route: 'mobile-session-expired'
          })
        );
      })();
      return;
    }

    if (marketingConsent !== state.marketingConsent) {
      patch(
        process.env.REACT_APP_API + '/user',
        {
          marketing_cons: state.marketingConsent ? '1' : '0'
        },
        {
          headers: { Authorization: user.signInUserSession?.idToken?.jwtToken }
        }
      )
        .then(res =>
          res.status === 200
            ? Auth.updateUserAttributes(user, {
                'custom:marketing-cons': state.marketingConsent ? '1' : '0'
              })
            : setError(en?.Error_OOHPS)
        )
        .catch(() => setError(en?.Error_OOHPS));
    } else setError(en?.Error_Nothing_Changed);
  };
  return (
    <DashboardCard
      icon={<DashboardProfileIcon css="mr-2" />}
      title={en?.My_Details_label}
      containerTestData="my-account"
      css={'w-full mt-6 md:mr-6'}
    >
      <form className="" onSubmit={submit}>
        <div className="px-4 pb-6 space-y-6">
          <GenerateInput
            type="text"
            label={en?.First_Name}
            css="capitalize"
            width="w-full max-w-ooh-input md:max-w-sm md:w-80"
            disabled={true}
            {...getInputBind('given_name')}
            testData="first-name"
            isRequired={false}
          />
          <GenerateInput
            type="text"
            label={en?.Last_Name}
            css="capitalize"
            width="w-full max-w-ooh-input md:max-w-sm md:w-80"
            disabled={true}
            {...getInputBind('family_name')}
            testData="last-name"
            isRequired={false}
          />
          <GenerateInput
            type="email"
            label={en?.Notification_Email}
            width="w-full max-w-ooh-input md:max-w-sm md:w-80"
            disabled={true}
            {...getInputBind('email')}
            testData="email"
            isRequired={false}
          />
          <GenerateInput
            type="text"
            label={en?.Phone_account}
            width="w-full max-w-ooh-input md:max-w-sm md:w-80"
            disabled={true}
            {...getInputBind('phone_number')}
            testData="phone"
            isRequired={false}
          />
        </div>
        <div className="px-4 pb-6">
          <GenerateInput
            type="checkbox"
            {...getInputBind('marketingConsent')}
            css="m-0"
            testData="marketing-consent"
          >
            <span>{en?.Marketing_OOHPod}</span>
          </GenerateInput>
        </div>
        <div className="px-4 pb-8">
          <ErrorMessage error={error} icon />
          <button
            disabled={marketingConsent === state.marketingConsent}
            className={
              'bg-ooh-warm-gray rounded-lg text-lg font-heading font-bold text-white w-full max-w-ooh-input md:max-w-sm md:w-80 self-end h-11 ' +
              (marketingConsent !== state.marketingConsent ? '' : 'opacity-50')
            }
            test-data="edit-account-button"
          >
            {en?.Update_Account}
          </button>
        </div>
      </form>
    </DashboardCard>
  );
};

export default EditAccount;
