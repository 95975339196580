import { ErrorIcon } from '../../utils/Icons';

const ErrorMessage = ({ error, icon }) => {
  if (error === true) return null;
  if (icon && !error) return null;
  if (icon)
    return (
      <div className="flex flex-row items-start my-4">
        <ErrorIcon css="pt-0.5" />
        <span className="error ml-2">{error}</span>
      </div>
    );
  return <div className="error">{error}</div>;
};

export default ErrorMessage;
