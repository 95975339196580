import { HomeIcon } from '@heroicons/react/outline';

const DashboardCard = ({
  title,
  titleCss,
  css,
  children,
  testData,
  containerTestData,
  icon,
  buttonText,
  buttonCss,
  buttonAction,
  buttonIcon
}) => {
  return (
    <div
      test-data={containerTestData}
      className={
        'bg-white border-2 border-ooh-line rounded-2xl ' +
        (css ? css : 'w-full mt-6')
      }
    >
      {title?.length ? (
        <div className="flex justify-between items-center">
          <div
            test-data={testData}
            className={
              'flex items-center p-4 ' +
              (titleCss ? titleCss : 'text-ooh-warm-gray')
            }
          >
            {icon ? icon : <HomeIcon className="h-6 w-6 mr-4" />}
            <h5 className="font-bold">{title}</h5>
          </div>
          {buttonText?.length ? (
            <div>
              <button
                onClick={buttonAction}
                className={
                  buttonCss?.length
                    ? buttonCss
                    : 'flex border border-ooh-warm-gray rounded-lg items-center py-2.5 px-5 mr-4 text-lg font-heading font-bold text-ooh-warm-gray leading-7 text-ooh-18'
                }
              >
                {buttonText}
                {buttonIcon}
              </button>
            </div>
          ) : null}
        </div>
      ) : null}

      {children}
    </div>
  );
};

export default DashboardCard;
