import { spacedID } from '../../utils/functions';
import { useState, useEffect, useMemo } from 'react';
import useOrders from '../../utils/hooks/useOrders';
import Table from '../Table';
import {
  CopyIcon,
  DashboardProfileIcon,
  DashboardHeartIcon,
  DashboardLocationsIcon,
  DashboardRightArrowIcon,
  HandEmoji,
  SendAParcelIcon,
  ReturnAParcelIcon
} from '../../utils/Icons';
import DashboardSmallCard from '../layout/DashboardSmallCard';
import LocationsList from '../layout/LocationsList';
import sendAParcelImage from '../../assets/images/SendParcelImage.png';
import returnAParcelImage from '../../assets/images/ReturnParcelImage.png';
import { en } from '../../utils/translations/en';

const ORDERS_HEADERS = [
  en?.Created_orders,
  en?.Tracking_Number_orders,
  en?.Location_orders,
  en?.Status_orders,
  en?.Last_Updated_orders
];
const EARTH_RADIUS = 6378137;
const TO_RADIUS = value => (value * Math.PI) / 180;

const calculateDist = (from = {}, to = {}) => {
  const distance =
    Math.acos(
      Math.sin(TO_RADIUS(to.latitude)) * Math.sin(TO_RADIUS(from.latitude)) +
        Math.cos(TO_RADIUS(to.latitude)) *
          Math.cos(TO_RADIUS(from.latitude)) *
          Math.cos(TO_RADIUS(from.longitude) - TO_RADIUS(to.longitude))
    ) * EARTH_RADIUS;

  return Math.round(distance * 0.001);
};

const errorGetLocation = () => {
  console.log('Unable to retrieve your location');
};

//this function takes an unsorted array of locations and sorts it alphabetically based on country and default location id
const sortLocations = (allAddresses, browserLocation = {}, defaultLocation) => {
  if (allAddresses?.locations?.length) {
    const countryLocations = allAddresses.locations.filter(
      address => address.showInDashboard
    );

    if (browserLocation?.latitude) {
      return countryLocations.sort(function (a, b) {
        if (!a.hasOwnProperty('distance')) {
          a.distance = calculateDist(browserLocation, a.location);
        }
        if (!b.hasOwnProperty('distance')) {
          b.distance = calculateDist(browserLocation, b.location);
        }
        return a.distance - b.distance;
      });
    } else {
      const sortedLocations = countryLocations.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      return sortedLocations.sort(function (a, b) {
        return a.id === defaultLocation ? -1 : b.id === defaultLocation ? 1 : 0;
      });
    }
  }
  return [];
};

const Dashboard = ({ address, user }) => {
  const {
    preferred_username,
    given_name,
    family_name,
    'custom:residential-plan': residentialPlan = '',
    'custom:corporate-plan': corporatePlan = '',
    'custom:favourite-location': favouriteLocation
  } = user?.attributes || {};

  const initialBrowserLocation = useMemo(
    () => JSON?.parse(sessionStorage.getItem('userLocation')) || {},
    []
  );

  const { orders, noOrders, loading, getNext, pages, apiError } =
    useOrders(user);
  const [copied, setCopied] = useState(false);
  const [residentialAddress, setResidentialAddress] = useState('');
  const [corporateAddress, setCorporateAddress] = useState('');
  const [openList, setOpenList] = useState(false);
  const [browserLocation, setBrowserLocation] = useState(
    initialBrowserLocation
  );
  const [allAddresses, setAllAddresses] = useState(sortLocations(address));

  const copyIcon = <CopyIcon />;
  const oohIdIcon = <DashboardProfileIcon />;
  const heartIcon = <DashboardHeartIcon />;
  const locationsIcon = <DashboardLocationsIcon />;
  const rightArrow = <DashboardRightArrowIcon />;

  useEffect(() => {
    if (residentialPlan?.length) {
      const resAddress = address.locations.filter(
        key => key.residentialName === residentialPlan
      );
      setResidentialAddress(resAddress);
    }
    if (corporatePlan?.length) {
      const corpAddress = address.locations.filter(
        key => key.corporateName === corporatePlan
      );
      setCorporateAddress(corpAddress);
    }
  }, [address, residentialPlan, corporatePlan]);

  useEffect(() => {
    let isMounted = true;
    if (!Object?.keys(initialBrowserLocation)?.length) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            if (isMounted) {
              setBrowserLocation(position?.coords);
              sessionStorage.setItem(
                'userLocation',
                JSON.stringify({
                  latitude: position?.coords?.latitude,
                  longitude: position?.coords?.longitude
                })
              );
            }
          },
          error => {
            errorGetLocation();
          },
          { enableHighAccuracy: false }
        );
      } else {
        console.log('Geolocation not supported');
      }
    }
    return () => {
      isMounted = false;
    };
  }, [initialBrowserLocation]);

  useEffect(() => {
    if (browserLocation?.latitude > 0) {
      setAllAddresses(sortLocations(address, browserLocation));
    }
  }, [address, browserLocation]);

  useEffect(() => {
    const isRedirect = sessionStorage?.getItem('locationsRedirect');
    if (isRedirect?.length) {
      setOpenList(true);
      sessionStorage.removeItem('locationsRedirect');
    }
  }, []);

  const favLocation = id =>
    address.locations.filter(location => location?.id === id);

  const renderFavouriteLocation = residentialPlan?.length
    ? residentialAddress?.[0]?.full_name
    : corporatePlan?.length
    ? corporateAddress?.[0]?.full_name
    : favouriteLocation?.length
    ? favLocation(favouriteLocation)?.[0]?.full_name.includes('Jonesborough')
      ? 'OOHPod Jonesborough'
      : favLocation(favouriteLocation)?.[0]?.full_name
    : en?.Add_your_favourite;

  const favAddress = residentialPlan?.length
    ? residentialAddress?.[0]?.address
    : corporatePlan?.length
    ? corporateAddress?.[0]?.address
    : favouriteLocation?.length
    ? favLocation(favouriteLocation)?.[0]?.address
    : '';

  const copyOOHId = () => {
    const el = document.createElement('input');
    el.value = preferred_username;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied('oohId');
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const favLocationAction = () => {
    let copyAddress = residentialPlan?.length
      ? residentialAddress?.[0]?.address
      : corporatePlan?.length
      ? corporateAddress?.[0]?.address
      : favouriteLocation?.length
      ? favLocation(favouriteLocation)?.[0]?.address
      : '';
    if (copyAddress?.length) {
      const el = document.createElement('input');
      el.value = `${given_name} ${family_name} ${preferred_username} ${copyAddress}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopied('favLocation');
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } else {
      openSideNav();
    }
  };

  const openSideNav = () => {
    setOpenList(true);
  };

  return (
    <>
      <div className="pb-6 text-base font-bold md:pb-8">
        <div className="flex justify-between flex-col md:flex-row md:items-center">
          <h4 className="font-bold text-white mb-8 flex flex-1 pb-0 items-center">
            {en?.Welcome_back} <HandEmoji css={'ml-2'} />
          </h4>
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="flex w-full flex-col md:flex-row lg:w-2/3">
            <DashboardSmallCard
              icon={oohIdIcon}
              text={spacedID(preferred_username)}
              buttonIcon={copyIcon}
              buttonAction={copyOOHId}
              buttonName="oohId"
              containerCss={'mb-6 md:mb-0 md:mr-6 lg:mr-8'}
              textCss={'text-ooh-22'}
              copied={copied}
              copiedText={en?.OOHID_Copied}
            />
            <DashboardSmallCard
              icon={locationsIcon}
              text={en?.Browse_our_locations}
              buttonIcon={rightArrow}
              buttonAction={openSideNav}
              containerCss={'lg:mr-2.5'}
              textCss={'text-ooh-18'}
            />
          </div>
          <div className="mt-6 lg:mt-0 lg:pl-5.5 lg:w-1/3">
            <DashboardSmallCard
              icon={heartIcon}
              text={renderFavouriteLocation}
              buttonIcon={
                renderFavouriteLocation !== en?.Add_your_favourite
                  ? copyIcon
                  : rightArrow
              }
              buttonAction={favLocationAction}
              buttonName="favLocation"
              textCss={'text-ooh-18'}
              copied={copied}
              copiedText={'Address Copied!'}
              favAddress={favAddress}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6 md:flex-row lg:mt-8">
          <div className="bg-white border-2 border-ooh-line rounded-2xl p-4 flex flex-1 items-center mb-6 md:mb-0 md:mr-6 relative">
            <div className="flex flex-1 flex-col">
              <div className="flex items-center">
                <SendAParcelIcon css="mr-2" />
                <h4 className="font-heading text-ooh-warm-gray pb-0 text-ooh-18">
                  {en?.Send_a_Parcel_dashboard}
                </h4>
              </div>
              <div className="mt-4 mb-2 flex flex-col items-center lg:items-start lg:w-fit lg:mb-4 lg:mt-14">
                <img
                  className="lg:hidden max-w-ooh-192 mb-4"
                  src={sendAParcelImage}
                  alt="Send a Parcel"
                />
                <a
                  className="border border-ooh-warm-gray font-heading text-lg font-bold px-10 py-3 text-ooh-warm-gray rounded-lg inline-block"
                  href="https://help.oohpod.com/swap-and-send-using-oohpod"
                  target="_blank"
                  rel="noreferrer"
                >
                  {en?.Learn_more_button}
                </a>
              </div>
            </div>
            <div>
              <img
                className="hidden lg:block max-w-ooh-192 mr-4"
                src={sendAParcelImage}
                alt="Send a Parcel"
              />
            </div>
            <span className="rounded-3xl bg-ooh-rust py-1 px-4 text-white text-bold text-xs absolute top-2 right-2">
              New
            </span>
          </div>

          <div className="bg-white border-2 border-ooh-line rounded-2xl p-4 flex-1 flex items-center">
            <div className="flex flex-1 flex-col">
              <div className="flex items-center">
                <ReturnAParcelIcon css="mr-2" />
                <h4 className="font-heading text-ooh-warm-gray pb-0 text-ooh-18">
                  {en?.Return_a_Parcel_dashboard}
                </h4>
              </div>
              <div className="mt-4 mb-2 flex flex-col items-center lg:items-start lg:w-fit lg:mb-4 lg:mt-14">
                <img
                  className="max-w-ooh-192 mb-4 lg:hidden"
                  src={returnAParcelImage}
                  alt="Return a Parcel"
                />
                <a
                  className="border border-ooh-warm-gray font-heading text-lg font-bold px-10 py-3 text-ooh-warm-gray rounded-lg inline-block"
                  href="https://help.oohpod.com/can-i-use-an-oohpod-locker-for-returns"
                  target="_blank"
                  rel="noreferrer"
                >
                  {en?.Learn_more_button}
                </a>
              </div>
            </div>
            <div>
              <img
                className="hidden max-w-ooh-192 mr-4 lg:block"
                src={returnAParcelImage}
                alt="Return a Parcel"
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        name={en?.Recent_Orders}
        headers={ORDERS_HEADERS}
        data={orders}
        noData={noOrders}
        loading={loading}
        getNext={getNext}
        pages={pages}
        apiError={apiError}
      />
      <LocationsList
        user={user}
        given_name={given_name}
        family_name={family_name}
        oohid={preferred_username}
        locations={allAddresses}
        openList={openList}
        setOpenList={setOpenList}
        hideFavourite={corporatePlan?.length || residentialPlan?.length}
        favouriteLocation={favouriteLocation}
      />
    </>
  );
};

export default Dashboard;
