import React from 'react';
import Image from '../../assets/images/error.png';
import { Icon404, RightArrowIcon } from '../../utils/Icons';
import { Link } from 'react-router-dom';
import { en } from '../../utils/translations/en';

const Page404 = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center font-bold mt-12">
      <img src={Image} alt="404" />
      <div className="flex flex-col items-center lg:items-start font-normal text-white">
        <h1>404</h1>
        <h4 className="mt-2 text-white">{en?.Page_not_found}</h4>
        <Icon404 />
        <p className="p-0 mb-3 text-white">
          {en?.Error_description}{' '}
          <a href="/" className="font-bold text-white">
            {en?.Dashboard_word} <RightArrowIcon />
          </a>
        </p>
        <p className="p-0 w-60 lg:w-full text-white">
          {en?.Error_support}{' '}
          <Link to="/support" className="font-bold">
            {en?.Support_word} <RightArrowIcon />
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Page404;
