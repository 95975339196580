import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { SignOutIcon } from '../../../utils/Icons';
import { useLogout } from '../../../utils/hooks/Auth';
import { useHistory, useLocation } from 'react-router';
import { classNames } from '../utils';
import useNavigators from '../../../utils/hooks/useNavigators';
import sessionManagement from '../../../utils/userSessionClickEvents';
import { Auth } from 'aws-amplify';
import { en } from '../../../utils/translations/en';

const MobileSidebar = ({
  sidebarOpen,
  setSidebarOpen,
  subscribed,
  subscriptionStatus,
  user
}) => {
  const location = useLocation();
  const history = useHistory();
  const navigators = useNavigators(subscribed, subscriptionStatus);
  const logout = useLogout();

  const handleSignOut = () => {
    setSidebarOpen(false);
    logout();
  };
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 lg:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col pt-5 bg-white my-4 mx-2 rounded-lg z-50">
            <div className="absolute top-4 right-14 -mr-12 pt-2">
              <button
                type="button"
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => setSidebarOpen(false)}
              >
                <span className="sr-only">Close sidebar</span>
                <XIcon className="h-6 w-6 text-black" aria-hidden="true" />
              </button>
            </div>

            <div className="flex-shrink-0 flex items-center px-4">
              <a test-data="logo" href="/">
                <img
                  className="max-h-20 w-auto"
                  src="/OOHPod_Logo.svg"
                  alt="OOHPod"
                />
              </a>
            </div>
            <div
              className="my-10 overflow-y-auto w-72"
              test-data="mobile-navigation-cart"
            >
              <nav className="px-5 space-y-1">
                {navigators.map((item, index) => (
                  <p
                    key={item.name}
                    onClick={() => {
                      setSidebarOpen(false);
                      //check session
                      const isExpired = sessionManagement(user);
                      if (isExpired) {
                        (async () => {
                          await Auth.signOut().then(() =>
                            history.push('/login', {
                              route: 'mobile-session-expired'
                            })
                          );
                        })();
                        return;
                      }
                      if (item.action) item.action();
                      else history.push(item.href);
                    }}
                    className={classNames(
                      location.pathname === item.href
                        ? 'bg-ooh-active-field text-white'
                        : 'text-ooh-warm-gray hover:bg-gray-50 hover:text-ooh-warm-gray',
                      'group flex items-center px-4 py-2 text-base font-medium rounded-md cursor-pointer'
                    )}
                    test-data={`navigation-item-${index}`}
                  >
                    <item.icon
                      className={classNames(
                        location.pathname === item.href
                          ? 'text-white'
                          : 'text-ooh-warm-gray group-hover:text-ooh-warm-gray',
                        'mr-4 flex-shrink-0 h-6 w-6'
                      )}
                      iconColor={
                        location.pathname === item.href ? '#FFFFFF' : '#564B51'
                      }
                      aria-hidden="true"
                    />
                    {item.name}
                  </p>
                ))}
              </nav>
            </div>
            <div className="bg-ooh-silver py-8 px-7">
              <button
                onClick={handleSignOut}
                className="flex items-center text-ooh-warm-gray hover:bg-gray-50 py-1 px-4 rounded-lg"
              >
                <SignOutIcon css={'mr-4'} /> {en?.Sign_out}
              </button>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileSidebar;
