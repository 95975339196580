import React, { useState } from 'react';
import { LocationsRightArrow } from '../../utils/Icons';
import { en } from '../../utils/translations/en';

function Location({
  given_name,
  family_name,
  oohid,
  location = {},
  setOpenDetails,
  setSelectedLocation
}) {
  const { full_name, address, distance } = location;
  const [copied, setCopied] = useState(false);

  const copyAddress = () => {
    const el = document.createElement('input');
    el.value = `${given_name} ${family_name} ${oohid} ${address}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleClick = () => {
    setSelectedLocation(location);
    setOpenDetails(true);
  };

  return (
    <div className="flex flex-row items-center border-b border-ooh-line px-4 pt-2 pb-4 lg:px-8">
      <div className="pr-8">
        <div
          className="text-ooh-warm-gray font-bold mb-2 cursor-pointer"
          onClick={handleClick}
          type="button"
        >
          {full_name?.includes('Jonesborough')
            ? 'OOHPod Jonesborough'
            : full_name}
        </div>
        <div
          onClick={handleClick}
          type="button"
          className="text-ooh-blue-ui text-sm cursor-pointer"
        >
          {address}
        </div>
        {distance > 0 ? (
          <div className="text-ooh-rust text-sm">{distance} km</div>
        ) : null}
        <button
          className="mt-4 border border-ooh-warm-gray rounded-lg text-ooh-warm-gray px-4 py-3"
          onClick={e => copyAddress(e)}
        >
          {copied ? en?.Copied : en?.Copy_Address}
        </button>
      </div>

      <button onClick={handleClick}>
        <LocationsRightArrow />
      </button>
    </div>
  );
}

export default Location;
