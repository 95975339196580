import React from 'react';

function LoadingSpinner({ text, loading = false }) {
  return (
    <div className="spinnerContainer">
      {loading ? <span className="loader"></span> : <span>{text}</span>}
    </div>
  );
}

export default LoadingSpinner;
