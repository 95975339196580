import { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

const useEmailVerifier = (user, setVerified) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const verify = useCallback(() => {
    setVerified(true);
    setLoading(false);
  }, [setVerified]);

  useEffect(() => {
    setLoading(true);
    const { email_verified } = user?.attributes || {};
    if (email_verified == null) return;
    if (email_verified === false && localStorage.getItem('cr') !== 'true') {
      // if the user verified his email, continue the flow. It's expected that he cannot reach this page if he didn't validate his phone no initialy
      //send the verification code through email
      if (!localStorage.getItem('cs'))
        Auth.currentAuthenticatedUser()
          .then(user =>
            Auth.verifyUserAttribute(user, 'email').catch(() =>
              setError('Could not verify your account')
            )
          )
          .then(() => {
            localStorage.setItem('cs', true);
            setVerified(false);
          })
          .catch(() => {
            setError('OOHPS! There was an error. Please try again later.');
            setVerified(false);
          });
    } else {
      setError('');
      verify();
    }
    setLoading(false);
  }, [user, verify, setVerified]);

  return { verify, error, loading };
};

export default useEmailVerifier;
