import { useEffect, useState } from 'react';
import { get } from '../../services/Api';

const useCommPreferences = user => {
  const [loading, setLoading] = useState(true);
  const [marketingConsent, setMarketingConsent] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (Object.keys(user)?.length) {
      (async () => {
        const options = {
          headers: {
            Authorization: user.signInUserSession?.idToken?.jwtToken
          }
        };
        return get(
          `${process.env.REACT_APP_API}/user/communication-preferences`,
          options
        )
          .then(({ data }) => {
            setMarketingConsent(data?.response?.marketingConsent);
            setLoading(false);
          })
          .catch(err => setLoading(false));
      })();
    }
  }, [user]);
  return {
    marketingConsent,
    loading
  };
};

export default useCommPreferences;
