import React, { useState } from 'react';
import {
  DashboardLeftArrow,
  What3WordsIcon,
  LinkIcon,
  DirectionsIcon,
  FavouriteIcon,
  ServicesCheckboxIcon,
  OpeningHoursIcon
} from '../../utils/Icons';
import { useHistory } from 'react-router';
import { Auth } from '@aws-amplify/auth';
import sessionManangement from '../../utils/userSessionClickEvents';
import { en } from '../../utils/translations/en';

function DetailedLocation({
  user,
  given_name,
  family_name,
  oohid,
  location = {},
  openDetails,
  setOpenDetails,
  hideFavourite,
  favouriteLocation
}) {
  const {
    full_name = '',
    address = '',
    what3words = '',
    allow_oversize = false,
    yodel_location = false,
    rm_location = false,
    allow_24_7,
    upsAPId = '',
    dhlId = '',
    link: locationLink = ''
  } = location;
  const history = useHistory();

  const [copied, setCopied] = useState(false);

  const copyAddress = () => {
    const el = document.createElement('input');
    el.value = `${given_name} ${family_name} ${oohid} ${address}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleFavourite = e => {
    //means that the user already set fav location and the attribute just needs to be created
    e.preventDefault();
    const isExpired = sessionManangement(user);
    if (isExpired) {
      (async () => {
        await Auth.signOut().then(() =>
          history.push('/login', {
            route: 'detailed-location-session-expired'
          })
        );
      })();
      return;
    }

    Auth.updateUserAttributes(user, {
      'custom:favourite-location':
        favouriteLocation === location?.id ? '' : location?.id
    });
  };

  return (
    <div
      className={`detailedLocation z-9999 bg-white max-w-ooh-locations-shelf fixed top-0 right-0 w-full h-full locationsShelfTransition overflow-y-auto ${
        openDetails ? 'translateX0' : 'translateX100'
      }`}
    >
      <div className="topNavigation p-4 border-b-2 border-ooh-line lg:p-8">
        <button onClick={() => setOpenDetails(false)}>
          <DashboardLeftArrow />
        </button>
      </div>
      <div className="text-ooh-warm-gray px-4 mt-3 lg:px-8">
        <p className="text-lg font-heading">
          {full_name?.includes('Jonesborough')
            ? 'OOHPod Jonesborough'
            : full_name}
        </p>
        <p className="pb-4">
          {address.split(',').map((a, index) => (
            <span key={index} className="pb-0 leading-6 block">
              {a}
              {index === address.split(',')?.length - 1 ? '' : ','}
            </span>
          ))}
        </p>
        <p className="flex items-center mb-5">
          <What3WordsIcon css={'mr-3'} />
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://what3words.com/${what3words}`}
            className="flex items-center"
          >
            what3words <LinkIcon css={'ml-4'} />
          </a>
        </p>

        <p className="pb-4 text-sm text-ooh-blue-ui font-bold flex items-center">
          <OpeningHoursIcon css="mx-1.5" />
          {allow_24_7 ? (
            '24/7'
          ) : (
            <button
              onClick={() => window.open(locationLink)}
              className="text-sm text-ooh-blue-ui font-bold flex items-center underline"
            >
              Check hours here <LinkIcon css={'ml-4'} />
            </button>
          )}
        </p>

        {allow_oversize ? (
          <p className="text-sm text-ooh-rust font-bold pb-2 flex items-center">
            <ServicesCheckboxIcon css="mx-1.5" /> {en?.Oversized_Parcels}
          </p>
        ) : null}
        {upsAPId?.length ? (
          <p className="pb-2 text-sm text-ooh-rust font-bold flex items-center">
            <ServicesCheckboxIcon css="mx-1.5" /> {en?.UPS_Access_Point}
          </p>
        ) : null}
        {yodel_location ? (
          <p className="pb-2 text-sm text-ooh-rust font-bold flex items-center">
            <ServicesCheckboxIcon css="mx-1.5" /> Yodel
          </p>
        ) : null}
        {rm_location ? (
          <p className="pb-2 text-sm text-ooh-rust font-bold flex items-center">
            <ServicesCheckboxIcon css="mx-1.5" /> Royal Mail
          </p>
        ) : null}
        {dhlId ? (
          <p className="pb-2 text-sm text-ooh-rust font-bold flex items-center">
            <ServicesCheckboxIcon css="mx-1.5" /> {en?.DHL_Service_Point}
          </p>
        ) : null}
      </div>
      <div className="px-4 mt-6 lg:px-8">
        <button
          onClick={copyAddress}
          className="text-ooh-warm-gray border border-ooh-warm-gray py-3 w-full rounded-lg mb-4"
        >
          {copied ? en?.Copied : en?.Copy_Address}
        </button>
        <button
          onClick={() => window.open(locationLink)}
          className="text-ooh-warm-gray border border-ooh-warm-gray py-3 w-full rounded-lg mb-4 flex items-center justify-center"
        >
          <DirectionsIcon css={'mr-2'} /> {en?.Get_Directions}
        </button>
        {!hideFavourite ? (
          <button
            onClick={handleFavourite}
            className="text-ooh-warm-gray border border-ooh-warm-gray py-3 w-full rounded-lg mb-4 flex items-center justify-center"
          >
            <FavouriteIcon
              color={favouriteLocation === location?.id ? '#62ACE4' : ''}
              css={'mr-2'}
            />
            {favouriteLocation === location?.id
              ? en?.Remove_as_favourite
              : en?.Add_as_favourite}
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default DetailedLocation;
