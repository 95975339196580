import React from 'react';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const SessionManagement = ({ user, children }) => {
  const loggedInTime =
    user?.signInUserSession?.accessToken?.payload['auth_time'];
  const history = useHistory();
  useEffect(() => {
    if (loggedInTime) {
      if (new Date().getTime() > loggedInTime * 1000 + 1 * 60 * 60 * 1000) {
        (async () => {
          await Auth.signOut({ global: true }).then(() =>
            history.push('/login', { route: 'session-expired' })
          );
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInTime]);
  return <>{children}</>;
};

export default SessionManagement;
