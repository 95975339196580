import React, { useState } from 'react';
import Confirmation from './Confirmation';
import RegistrationForm from './RegistrationForm';

const Register = () => {
  const [confirmation, setConfirmation] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState('');

  const handleConfirmationScreen = () => {
    return confirmation ? (
      <Confirmation
        confirmationType="phone"
        email={email}
        phone={phone}
        setConfirmation={setConfirmation}
      />
    ) : (
      <RegistrationForm
        setConfirmation={setConfirmation}
        setEmail={setEmail}
        setPhone={setPhone}
      />
    );
  };

  return handleConfirmationScreen();
};

export default Register;
