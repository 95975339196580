import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { CloseIcon } from '../../utils/Icons';
import { get } from '../../services/Api';
import { en } from '../../utils/translations/en';

const BuyBundleModal = ({ user, isOpen, onClose, onBuy, reset }) => {
  const { signInUserSession } = user;
  const jwtToken = signInUserSession?.idToken?.jwtToken;

  const [bundleQuantity, setBundleQuantity] = useState(1);
  const [price, setPrice] = useState(null);
  const [currency, setCurrency] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [purchaseComplete, setPurchaseComplete] = useState(false);

  useEffect(() => {
    if (reset) {
      // Reset modal state when reset is true
      setBundleQuantity(1);
      setPrice(null);
      setCurrency('');
      setDescription('');
      setLoading(true);
      setError(null);
      setConfirmationMessage('');
      setPurchaseComplete(false);
    }
  }, [reset]);

  useEffect(() => {
    let isMounted = true;

    if (isOpen) {
      const fetchBundleDetails = async () => {
        try {
          setLoading(true);
          const options = {
            headers: {
              Authorization: jwtToken
            }
          };
          const response = await get(
            `${process.env.REACT_APP_API}/user/bundle`,
            options
          );

          if (!response.data) {
            throw new Error('No bundle details returned from the server');
          }

          if (isMounted) {
            setPrice(response.data.price);
            setCurrency(response.data.currency);
            setDescription(response.data.description);
            setLoading(false);
          }
        } catch (err) {
          if (isMounted) {
            setError(err.message || 'Failed to load bundle details');
            setLoading(false);
          }
        }
      };

      fetchBundleDetails();
    }

    return () => {
      isMounted = false;
    };
  }, [isOpen, jwtToken]);

  const handleBuyNow = async () => {
    try {
      setLoading(true);
      const result = await onBuy(bundleQuantity);

      if (!result.success) {
        setError(result.message);
        setConfirmationMessage('');
        setPurchaseComplete(false); // Ensure the purchaseComplete state is not set
      } else {
        setConfirmationMessage('Bundle purchased successfully!');
        setError(null);
        setPurchaseComplete(true); // Mark the purchase as complete
      }
    } catch (err) {
      setError(
        'Failed to purchase bundle. Please check your payment card and try again.'
      );
      setConfirmationMessage('');
      setPurchaseComplete(false); // Ensure the purchaseComplete state is not set
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = e => {
    const value = e.target.value;

    if (/^\d+$/.test(value) && Number(value) >= 1 && Number(value) <= 99) {
      setBundleQuantity(Number(value));
    } else if (value === '') {
      setBundleQuantity(1);
    }
  };

  const handleClose = () => {
    setPurchaseComplete(false); // Reset the purchaseComplete state when modal is closed
    setConfirmationMessage(''); // Reset any confirmation message
    onClose(); // Call the onClose prop to close the modal
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4">
      <div className="bg-white text-ooh-warm-gray rounded-lg shadow-lg overflow-hidden w-full max-w-md sm:max-w-lg">
        <div className="p-4 border-b border-ooh-dark-gray relative">
          <h2 className="text-lg sm:text-ooh-22">{en?.Buy_Bundle}</h2>
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 text-ooh-warm-gray hover:text-black text-xl"
          >
            <CloseIcon />
          </button>
        </div>

        <div className="p-4 sm:p-6">
          {error && <p className="text-ooh-red">{error}</p>}

          <div className="flex items-center justify-between mb-4">
            <div className="flex-1 mb-4 sm:mb-0">
              <p className="text-ooh-dark-gray font-medium">{description}</p>
            </div>

            <div className="flex items-center space-x-4">
              {/* Native input with arrows (visible on large screens and above) */}
              <div className="hidden lg:block">
                <input
                  type="number"
                  value={bundleQuantity}
                  min="1"
                  max="99"
                  onChange={handleQuantityChange}
                  className="w-20 px-3 py-2 border border-ooh-warm-gray bg-ooh-gray-200 text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-ooh-blue"
                />
              </div>

              {/* Custom buttons (visible on mobile and tablet) */}
              <div className="flex items-center space-x-2 lg:hidden">
                <button
                  onClick={() =>
                    setBundleQuantity(prev => Math.max(1, prev - 1))
                  }
                  className="px-2 py-1 border rounded-l bg-ooh-gray-200 text-black"
                >
                  -
                </button>
                <input
                  type="number"
                  value={bundleQuantity}
                  min="1"
                  max="99"
                  onChange={handleQuantityChange}
                  className="w-16 text-center border-t border-b border-ooh-warm-gray bg-ooh-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-ooh-blue"
                  style={{
                    WebkitAppearance: 'none',
                    MozAppearance: 'textfield'
                  }}
                />
                <button
                  onClick={() =>
                    setBundleQuantity(prev => Math.min(99, prev + 1))
                  }
                  className="px-2 py-1 border rounded-r bg-ooh-gray-200 text-black"
                >
                  +
                </button>
              </div>
            </div>
          </div>

          {confirmationMessage && (
            <p className="text-ooh-green">{confirmationMessage}</p>
          )}

          <div className="flex justify-end mt-4">
            <p className="text-lg font-bold">
              Total: {currency} {(bundleQuantity * (price || 0)).toFixed(2)}
            </p>
          </div>
        </div>

        <div className="p-4 sm:p-6 border-t border-ooh-dark-gray flex flex-col sm:flex-row justify-end relative">
          {purchaseComplete ? (
            <button onClick={handleClose} className="form-primary-button">
              {en?.Close}
            </button>
          ) : (
            <button
              onClick={handleBuyNow}
              type="submit"
              className="form-primary-button"
              disabled={loading || error}
            >
              {en?.Buy_Now}
            </button>
          )}
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
              <LoadingSpinner text={'Processing...'} loading={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyBundleModal;
