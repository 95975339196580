import { useEffect, useState } from 'react';
import { get } from '../../services/Api';
import { useUser } from './Auth';

const useSubscriptionVerifier = (email_verified, emailLoading) => {
  // State to manage the loading status of the data fetching process
  const [loading, setLoading] = useState(true);

  // State to store the user's virtual address
  const [address, setAddress] = useState('');

  // State to determine if the user is an existing customer based on subscription status
  const [existingCustomer, setExistingCustomer] = useState(false);

  // State to manage whether the user has a current subscription
  const [subscribed, setSubscribed] = useState(false);

  // State to store the subscription ID
  const [subscriptionId, setSubscriptionId] = useState('');

  // State to store the subscription status (e.g., active, in_trial)
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  // State to store the name of the subscription plan
  const [subscriptionName, setSubscriptionName] = useState('');

  // State to store bundle information, which is specific to bundle subscription plans
  const [bundleInfo, setBundleInfo] = useState(null);

  // State to manage manual refresh trigger
  const [refresh, setRefresh] = useState(false);

  // Hook to get the current user information from the Auth context
  const user = useUser();

  useEffect(() => {
    // Function to fetch subscription data from the API
    const fetchSubscriptionData = async () => {
      try {
        // Early return if user object doesn't exist or email is still loading
        if (!user || emailLoading) return;

        // Set loading state to true before starting the API request
        setLoading(true);

        // If the email is not verified, stop the process and set loading to false
        if (!email_verified) {
          setLoading(false);
          return;
        }

        // Options object containing authorization header with JWT token
        const options = {
          headers: {
            Authorization: user.signInUserSession?.idToken?.jwtToken
          }
        };

        // Make the GET request to fetch user subscription data
        const { data } = await get(
          `${process.env.REACT_APP_API}/user`,
          options
        );

        // Destructure the response object for easy access to variables
        const {
          subscriptionId,
          status,
          virtualAddress,
          subscriptionName,
          bundleInfo
        } = data.response || {};

        // Set the subscription-related states based on the response
        setSubscriptionId(subscriptionId);
        setSubscriptionStatus(status);
        setAddress(virtualAddress);
        setSubscriptionName(subscriptionName);
        setBundleInfo(bundleInfo || null); // Set bundleInfo only if it exists, otherwise set to null

        // Determine if the user is an existing customer based on subscription status
        setExistingCustomer(
          ['in_trial', 'active', 'non_renewing', 'cancelled'].includes(status)
        );
      } catch (err) {
        // Log any error that occurs during the API request
        console.error('Failed to fetch subscription data');
      } finally {
        // Ensure that the loading state is set to false after the process completes
        setLoading(false);
      }
    };

    // Trigger the data fetching function when the hook dependencies change
    fetchSubscriptionData();
  }, [email_verified, user, subscribed, emailLoading, refresh]);

  // Return all the state variables and functions that might be used in the component
  return {
    existingCustomer,
    address,
    verify: setSubscribed, // Function to manually set the subscribed state
    loading,
    subscriptionStatus,
    subscriptionId,
    subscriptionName,
    bundleInfo,
    refresh,
    setRefresh // Function to manually trigger a refresh
  };
};

export default useSubscriptionVerifier;
