import React from 'react';
import { useState } from 'react';
import { MenuIcon } from '@heroicons/react/outline';
import { SignOutIcon } from '../../utils/Icons';
import MobileNavigation from './Navigation/MobileNavigation';
import DesktopNavigation from './Navigation/DesktopNavigation';
import { useLogout } from '../../utils/hooks/Auth';
import { en } from '../../utils/translations/en';

const Header = ({ user, subscribed, subscriptionStatus, emailVerified }) => {
  const logout = useLogout();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div>
      <div>
        <MobileNavigation
          subscribed={subscribed}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          subscriptionStatus={subscriptionStatus}
          user={user}
        />
        <div className="md:pl-0 flex flex-col flex-1">
          <div
            className={
              !Object.keys(user)?.length ||
              (Object.keys(user)?.length && !emailVerified)
                ? 'border-b-2 border-ooh-line md:border-b-0'
                : 'sticky top-0 z-10 flex-shrink-0 flex h-24 bg-white px-5'
            }
          >
            <div
              className={
                !Object.keys(user)?.length ||
                (Object.keys(user)?.length && !emailVerified)
                  ? 'flex-1 flex justify-center items-center'
                  : 'flex-1 md:-24 flex justify-between items-center max-w-screen-ooh-container w-full mx-auto'
              }
            >
              <a test-data="logo" href="/">
                <img
                  className={
                    !Object.keys(user)?.length ||
                    (Object.keys(user)?.length && !emailVerified)
                      ? 'h-16 my-2.5 md:h-32 md:mt-14 md:mb-0 w-full'
                      : 'max-h-20 w-full'
                  }
                  src="/OOHPod_Logo.svg"
                  alt="OOHPod"
                />
              </a>
              {Object.keys(user)?.length &&
              emailVerified &&
              subscriptionStatus !== 'Subscription not Found' &&
              subscriptionStatus !== null ? (
                <DesktopNavigation subscriptionStatus={subscriptionStatus} />
              ) : null}
              {Object.keys(user)?.length ? (
                <div
                  className={`${
                    emailVerified ? '' : 'absolute right-20 top-8'
                  } hidden lg:flex ml-4 items-center md:ml-6`}
                >
                  <button
                    onClick={logout}
                    className="flex items-center text-ooh-warm-gray hover:bg-gray-50 py-1 px-4 rounded-lg"
                  >
                    <SignOutIcon css={'mr-6'} /> {en?.Sign_out}
                  </button>
                </div>
              ) : null}
            </div>

            {Object.keys(user)?.length ? (
              <button
                type="button"
                className={`px-4 border-none border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden ${
                  emailVerified ? '' : 'absolute top-8 right-4'
                }`}
                onClick={() => setSidebarOpen(true)}
                test-data="hamburger-menu"
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-8 w-8" aria-hidden="true" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
