import Dashboard from './user/Dashboard';
import Account from './user/Account';
import Support from './user/Support';
import { Notifications } from './Notifications';
import { get } from '../services/Api';
import sessionManangement from '../utils/userSessionClickEvents';
import { useHistory } from 'react-router';
import Page404 from './errors/404';
import { Route, Switch } from 'react-router-dom';
import Locations from './user/Locations';
import { Auth } from 'aws-amplify';
import Orders from './user/Orders';

export default function UserDashboard({
  address,
  subscriptionStatus,
  subscriptionName,
  subscriptionId,
  refresh,
  setRefresh,
  user,
  bundleInfo // Add bundleInfo to the props
}) {
  const history = useHistory();

  const handleSubscriptionReactivation = () => {
    const isExpired = sessionManangement(user);
    if (isExpired) {
      (async () => {
        await Auth.signOut().then(() =>
          history.push('/login', {
            route: 'user-dashboard-session-expired'
          })
        );
      })();
      return;
    }
    // get CB Instance
    const cbInstance = window.Chargebee.getInstance();

    // create CB Portal instance
    var cbPortal = cbInstance.createChargebeePortal();

    cbInstance.setPortalSession(function () {
      // This function should return a promise, that will resolve a portal session object
      return new Promise(function (resolve, reject) {
        const options = {
          headers: { Authorization: user.signInUserSession?.idToken?.jwtToken }
        };
        const response = get(
          `${process.env.REACT_APP_API}/user/self-serve`,
          options
        )
          .then(res => {
            if (Object.keys(res?.data?.response?.portal_session)?.length)
              return res?.data?.response?.portal_session;
          })
          .catch(err => {
            //handle error (navigate to 500)
          });
        resolve(response);
      });
    });

    // open portal
    cbPortal.open({
      close: function () {
        // called when chargebee portal is closed
        setRefresh(!refresh);
      }
    });
  };

  const renderNotification = () => {
    let notificationType;
    let notificationText;

    if (subscriptionStatus === 'non_renewing') {
      notificationType = 'warning';
      notificationText = (
        <span>
          Your subscription will be cancelled at the end of current billing
          period. <span className="underline">Restart here.</span>
        </span>
      );
    } else if (subscriptionStatus === 'cancelled') {
      notificationType = 'error';
      notificationText = (
        <span>
          Your subscription has been cancelled.{' '}
          <span className="underline">Restart here.</span>
        </span>
      );
    }

    let showNotification = true;
    if (subscriptionStatus === 'active' || subscriptionStatus === 'in_trial') {
      showNotification = false;
    }

    return (
      <Notifications type={notificationType} show={showNotification}>
        <button onClick={handleSubscriptionReactivation}>
          {notificationText}
        </button>
      </Notifications>
    );
  };

  const renderRoutes = () =>
    subscriptionStatus === 'cancelled' ? (
      <Switch>
        <Route path="/">
          <Support />
        </Route>
        <Route component={Page404} />
      </Switch>
    ) : (
      <Switch>
        <Route exact path="/">
          <Dashboard address={address} user={user} />
        </Route>
        <Route path="/account">
          <Account
            refresh={refresh}
            setRefresh={setRefresh}
            user={user}
            subscriptionName={subscriptionName}
            subscriptionId={subscriptionId}
            bundleInfo={bundleInfo}
          />
        </Route>
        <Route path="/locations">
          <Locations />
        </Route>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/orders">
          <Orders user={user} />
        </Route>
        <Route component={Page404} />
      </Switch>
    );

  return (
    <>
      <div className="flex flex-1">
        <main className="flex-1 bg-ooh-dashboard-bg bg-ooh-dashboard-gradient overflow-x-hidden">
          {renderNotification()}
          <div className="max-w-5xl mx-auto px-2 py-8 md:px-6">
            <div className="">{renderRoutes()}</div>
          </div>
        </main>
      </div>
    </>
  );
}
