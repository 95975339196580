import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Main from './components/Main';
import { COGNITO, PINPOINT } from './configs/aws';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import { useUser } from './utils/hooks/Auth';
import SessionManagement from './SessionManagement';
import { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Analytics } from '@aws-amplify/analytics';
import TagManager from 'react-gtm-module';
import useEmailVerifier from './utils/hooks/useEmailVerifier';
import useSubscriptionVerifier from './utils/hooks/useSubscriptionVerifier';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID
});

const amplifyConfig = {
  Auth: {
    identityPoolId: COGNITO.IDENTITY_POOL_ID,
    region: COGNITO.REGION
  }
};

// Initialize Amplify
Auth.configure(amplifyConfig);

const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: PINPOINT.PROJECT_ID,
    // Amazon service region
    region: COGNITO.REGION,
    mandatorySignIn: false
  }
};

Analytics.configure(analyticsConfig);

// Automatically track page views with Amazon Pinpoint for SPA
Analytics.autoTrack('pageView', {
  enable: true,
  type: 'SPA'
});

// Configure and initialize Google Tag Manager (GTM)
const tagManagerArgs = {
  gtmId: 'GTM-P7HTXSD'
};

TagManager.initialize(tagManagerArgs);

function App() {
  const user = useUser() || {};
  const [subscribed, setSubscribed] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [emailVerified, setVerified] = useState(false);
  const [bundleInfo, setBundleInfo] = useState(null); // New state to store bundleInfo

  const {
    verify: verifyEmail,
    loading: emailLoading,
    error
  } = useEmailVerifier(user, setVerified);

  const {
    existingCustomer,
    verify: verifySubscription,
    loading: subscriptionLoading,
    address,
    subscriptionStatus: hookSubStatus,
    subscriptionName,
    refresh,
    setRefresh,
    subscriptionId,
    bundleInfo: hookBundleInfo // Extract bundleInfo from the hook
  } = useSubscriptionVerifier(emailVerified, emailLoading, user);

  useEffect(() => {
    setSubscribed(existingCustomer);
  }, [existingCustomer, setSubscribed]);

  useEffect(() => {
    setSubscriptionStatus(hookSubStatus);
  }, [hookSubStatus, setSubscriptionStatus]);

  useEffect(() => {
    // Update the bundleInfo state whenever hookBundleInfo changes
    setBundleInfo(hookBundleInfo);
  }, [hookBundleInfo]);

  return (
    <Router>
      <div className="App">
        <div
          className={`min-h-screen mx-auto flex flex-col ${
            !Object.keys(user)?.length ||
            (Object.keys(user)?.length && !emailVerified)
              ? 'bg-transparent md:bg-ooh-login-pattern bg-center bg-cover bg-no-repeat'
              : ''
          }`}
        >
          <Header
            user={user}
            subscribed={subscribed}
            subscriptionStatus={subscriptionStatus}
            emailVerified={emailVerified}
          />
          <div className="flex flex-1 flex-col">
            <SessionManagement user={user}>
              <Switch>
                <Route
                  path="/register"
                  render={props =>
                    Object.keys(user)?.length ? (
                      <Redirect to="/" />
                    ) : (
                      <Register />
                    )
                  }
                />
                <Route
                  path="/login"
                  render={props =>
                    Object.keys(user)?.length ? (
                      <Redirect to="/" />
                    ) : (
                      <Login setVerified={setVerified} />
                    )
                  }
                />
                <Route
                  path="/forgot"
                  render={props =>
                    Object.keys(user)?.length ? (
                      <Redirect to="/" />
                    ) : (
                      <ForgotPassword />
                    )
                  }
                />
                <Route
                  path="/reset"
                  render={props =>
                    Object.keys(user)?.length ? (
                      <Redirect to="/" />
                    ) : (
                      <ResetPassword />
                    )
                  }
                />
                <Route path="/">
                  <ProtectedRoute>
                    <Main
                      emailVerified={emailVerified}
                      verifyEmail={verifyEmail}
                      verifySubscription={verifySubscription}
                      subscribed={subscribed}
                      address={address}
                      subscriptionStatus={subscriptionStatus}
                      subscriptionName={subscriptionName}
                      subscriptionId={subscriptionId}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      error={error}
                      emailLoading={emailLoading}
                      subscriptionLoading={subscriptionLoading}
                      user={user}
                      bundleInfo={bundleInfo} // Pass bundleInfo to Main component
                    />
                  </ProtectedRoute>
                </Route>
              </Switch>
            </SessionManagement>
          </div>
          <Footer />
        </div>
      </div>
      <div id="reCaptchaContainer"></div>
    </Router>
  );
}

export default App;
