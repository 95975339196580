import React from 'react';
import { NotificationIcon } from '../utils/Icons';

export const Notifications = ({ type, show, children }) => {
  const backgroundColor = type === 'warning' ? 'bg-ooh-rust' : 'bg-ooh-red';
  if (!show) return null;
  return (
    <div className={`${backgroundColor} px-5 py-6`}>
      <div className="max-w-screen-ooh-container w-full mx-auto flex flex-row text-white">
        <NotificationIcon css="mt-0.5" />
        <p className="pl-2 pb-0 md:pl-3 ">{children}</p>
      </div>
    </div>
  );
};
