import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon
} from '@heroicons/react/solid';
import { OrdersIcon, OrdersTableRightArrow } from '../utils/Icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { en } from '../utils/translations/en';

export default function Table({
  name,
  headers,
  data,
  loading,
  noData,
  performedSearch = false,
  getNext,
  pages,
  dashboardTable = true,
  from = '',
  to = '',
  currentPage = 1,
  setCurrentPage,
  apiError = false
}) {
  const [ordersPerPage] = useState(10);

  if (apiError) {
    return (
      <div className="bg-white border-2 border-ooh-line rounded-xl px-4 pt-4 pb-8 mb-6">
        <h3 className="text-ooh-warm-gray text-ooh-18 font-heading mb-3">
          {en?.Error_order_request}
        </h3>
      </div>
    );
  }

  if (noData && !loading)
    return (
      <div className="bg-white border-2 border-ooh-line rounded-xl px-4 pt-4 pb-8 mb-6">
        {performedSearch ? (
          <>
            <h3 className="text-ooh-warm-gray text-ooh-18 font-heading mb-3">
              {en?.Error_no_orders}
            </h3>
            <h3 className="text-ooh-warm-gray text-ooh-18 font-heading mb-3">
              {en?.Error_Search_Help}
            </h3>
            <ul className="text-ooh-warm-gray list-disc pl-6">
              <li>{en?.Error_Check_typos}</li>
              <li>{en?.Error_confirm_tr_no}</li>
              <li>{en?.Error_Adjust_filter_date}</li>
            </ul>
          </>
        ) : (
          <h3 className="text-ooh-warm-gray text-ooh-18 font-heading mb-3">
            {en?.Error_looks_like}
          </h3>
        )}
      </div>
    );

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center flex-grow">
        <span className="page-loading"></span>
      </div>
    );
  }

  //calculate the total no of pages and create an array
  const totalPages = pages;
  const pagesArray =
    totalPages < 7
      ? Array.from({ length: totalPages }, (_, i) => i + 1)
      : [
          1,
          2,
          3,
          currentPage < 4 || currentPage > totalPages - 3 ? '...' : currentPage,
          totalPages - 2,
          totalPages - 1,
          totalPages
        ];

  // Get current page orders
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = data?.slice(indexOfFirstOrder, indexOfLastOrder);
  // handle next navigation
  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    if ((currentPage + 1) % 2 === 0 && (currentPage + 1) * ordersPerPage >= 40)
      getNext((currentPage + 1) * ordersPerPage, from, to);
  };

  // handle previous navigation
  const handlePrevious = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
    if ((currentPage - 2) % 2 === 0 && currentPage > 2) {
      getNext((currentPage - 3) * ordersPerPage, from, to);
    }
  };

  const renderNavigation = () => (
    <nav className="border-t border-ooh-line px-4 flex items-center justify-between sm:px-0 bg-white py-4 pt-0 text-left font-bold text-base text-ooh-warm-gray">
      <div className="w-0 flex-1 flex px-7">
        <button
          disabled={currentPage === 1}
          onClick={handlePrevious}
          className={
            'border-t-2 border-transparent pt-4 pr-1 inline-flex items-center font-normal text-gray-500 hover:text-gray-700 hover:border-gray-300' +
            (currentPage === 1 ? ' opacity-25' : '')
          }
          test-data="table-previous-button"
        >
          <ArrowNarrowLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {en?.Previous_orders}
        </button>
      </div>
      <div className="hidden lg:flex">
        {pagesArray.map(number =>
          typeof number === 'number' ? (
            <button
              onClick={() => setCurrentPage(number)}
              key={number}
              aria-current={currentPage === number ? 'page' : 'none'}
              className={
                'border-t-2 pt-4 px-4 inline-flex items-center font-medium ' +
                (currentPage === number
                  ? 'border-ooh-rust text-ooh-rust'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300')
              }
              test-data="table-current-page"
            >
              {number}
            </button>
          ) : (
            <span
              key={number}
              className="border-transparent text-gray-500 'border-t-2 pt-4 px-4 inline-flex items-center font-medium"
              test-data="table-pages"
            >
              {number}
            </span>
          )
        )}
      </div>
      <div className="w-0 flex-1 flex justify-end px-7">
        <button
          disabled={currentPage === totalPages}
          onClick={handleNext}
          className={
            'border-t-2 border-transparent pt-4 pl-1 inline-flex items-center font-normal text-gray-500 hover:text-gray-700 hover:border-gray-300' +
            (currentPage === totalPages ? ' opacity-25' : '')
          }
          test-data="table-next-button"
        >
          {en?.Next_orders}
          <ArrowNarrowRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );

  const renderTableData = () => {
    return currentOrders?.length
      ? currentOrders?.map((row = {}, i) => (
          <tr key={i} className="border-b border-ooh-dark-line">
            {Object.keys(row).map((property, index) => (
              <td
                key={index}
                className={`py-4 whitespace-nowrap text-base font-normal text-ooh-warm-gray ${
                  property === 'createTime' ? 'hidden md:table-cell' : ''
                }`}
              >
                <span
                  className={`${getCellCss(
                    property,
                    row[property]
                  )} py-1 px-4 rounded-3xl text-ooh-warm-gray`}
                >
                  {row[property]}
                </span>
              </td>
            ))}
          </tr>
        ))
      : null;
  };

  return (
    <div className="flex flex-col">
      <div className="align-middle ">
        <div className="rounded-2xl overflow-hidden border-2 border-ooh-line bg-white">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    colSpan={5}
                    className="p-4 text-left font-bold text-base text-ooh-warm-gray font-heading text-ooh-18"
                  >
                    <div className="flex justify-between flex-col lg:flex-row">
                      <div className="flex flex-row items-center">
                        <OrdersIcon />
                        <span className="ml-2 text-lg" test-data="table-name">
                          {name}
                        </span>
                      </div>
                      {dashboardTable ? (
                        <Link
                          to="/orders"
                          className="flex border border-ooh-warm-gray rounded-lg items-center py-2.5 px-5 text-lg font-heading font-bold text-ooh-warm-gray leading-7 text-ooh-18 w-fit mt-4 lg:mt-0"
                        >
                          {en?.View_all_orders}{' '}
                          <OrdersTableRightArrow css={'ml-2'} />
                        </Link>
                      ) : null}
                    </div>
                  </th>
                </tr>
              </thead>
              <thead className="bg-ooh-dark-line border-none">
                <tr>
                  {headers.map(header => (
                    <th
                      key={header}
                      scope="col"
                      className={`p-4 text-left text-base text-ooh-warm-gray font-semibold capitalize ${
                        header === 'Created' ? 'hidden' : ''
                      } md:table-cell`}
                      test-data="table-header-cell"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white border-transparent">
                {renderTableData()}
              </tbody>
            </table>
          </div>
          {dashboardTable ? (
            <div className="py-6 bg-white"></div>
          ) : (
            renderNavigation()
          )}
        </div>
      </div>
    </div>
  );
}

const getCellCss = (column, cell) => {
  switch (column) {
    case 'statusDescription':
      switch (cell) {
        case 'Ready for collection':
        case 'Customer Stored':
          return 'bg-ooh-green-ui bg-opacity-25';
        case 'Expired':
        case 'Cancelled':
          return 'bg-ooh-red bg-opacity-25';
        case 'Expected':
          return 'bg-ooh-light-yellow-ui';
        default:
          return 'bg-ooh-pale-blue bg-opacity-25';
      }
    case 'trackingNumber':
      return 'font-semibold';
    default:
      return 'text-ooh-warm-gray';
  }
};
